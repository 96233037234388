import { Box } from "@chakra-ui/react";
import React from "react";
import BuyerSearchHeading from "./BuyerSearchHeading";
import BuyerFilters from "./BuyerFilters";
import BuyerListing from "./BuyerListing";
import BuyerDrawer from "./drawer-forms/BuyerDrawer";
import { useDelete, useGet } from "../../hooks/api-call";
import { useState } from "react";

const BuyerDashboard = () => {
  const [activePage, setActivePage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedBuyerForEdit, setSelectedBuyerForEdit] = useState(false);

  const { isLoading, responseData, fetchData } = useGet(
    `buyersWithPagination?pageNumber=${activePage}&rowsPerPage=${pageSize}&truckHypothecated=${""}`
  );

  const { deleteData } = useDelete();

  const fetchBuyersData = (activePage, details) => {
    const url = `buyersWithPagination?pageNumber=${activePage}&rowsPerPage=${pageSize}&buyerId=${
      details?.buyerId || ""
    }&materialId=${details?.materialId || ""}&tripStageId=${
      details?.tripStageId || ""
    }&minimumQuantity=${details?.minimumQuantity || ""}`;
    fetchData(url);
  };

  const handleRemoveTruck = async (buyerId) => {
    await deleteData(null, `buyers/${buyerId}`);
    fetchBuyersData(activePage);
  };

  const handleEditOpen = (buyer) => {
    setIsDrawerOpen(true);
    setSelectedBuyerForEdit(buyer);
  };

  return (
    <Box>
      <Box pl="5" pr="5">
        <BuyerSearchHeading setIsDrawerOpen={setIsDrawerOpen} />
        <BuyerFilters fetchBuyersData={fetchBuyersData} />
        <BuyerListing
          handleRemoveTruck={handleRemoveTruck}
          isLoading={isLoading}
          buyers={responseData?.buyers}
          activePage={activePage}
          setActivePage={setActivePage}
          pageSize={pageSize}
          totalPages={Math.ceil((responseData?.totalBuyers || 0) / pageSize)}
          fetchBuyersData={fetchBuyersData}
          handleEditOpen={handleEditOpen}
        />
      </Box>

      {isDrawerOpen && (
        <BuyerDrawer
          defaultValues={selectedBuyerForEdit}
          setIsDrawerOpen={setIsDrawerOpen}
          fetchBuyersData={fetchBuyersData}
          setSelectedBuyerForEdit={setSelectedBuyerForEdit}
        />
      )}
    </Box>
  );
};

export default BuyerDashboard;
