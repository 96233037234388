import React from "react";
import {
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  Select,
  Text,
} from "@chakra-ui/react";
import { useForm, Controller } from "react-hook-form";
import { BoxWrapper } from "../../../admin-section/trips/drawer-contents/TripDrawer";
import { usePost, usePut } from "../../../hooks/api-call";
import useCustomToast from "../../../hooks/use-toast";
import * as Yup from "yup";
import CustomFormLabel from "../../../atoms/CustomFormLabel";
import { useYupValidationResolver } from "../../../hooks/use-validation-resolver";
import {
  createEmailSchema,
  createGstSchema,
  createPanSchema,
  pincodeSchema,
} from "../../../hooks/validationSchemas";
import NewAddressDetails from "../../trucks/drawer-forms/NewAddressDetails";

const validationSchema = Yup.object().shape({
  category: Yup.string(),
  buyerName: Yup.string(),
  contactNo: Yup.string()
    // .required("Contact number is required")
    .matches(/^[0-9]{10}$/, "Invalid contact number"),
  emailId: createEmailSchema(),
  gstNo: createGstSchema(),
  panNo: createPanSchema(),
  // address: Yup.string().required("Address is required"),
  pincode: pincodeSchema,
});

const BuyerDrawer = ({
  setIsDrawerOpen,
  fetchBuyersData,
  setSelectedBuyerForEdit,
  defaultValues,
}) => {
  const { showSuccessToast } = useCustomToast();
  const { postData } = usePost();
  const { putData } = usePut();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting, isDirty, isValid },
  } = useForm({
    defaultValues: defaultValues,
    resolver: useYupValidationResolver(validationSchema),
    mode: "onBlur",
  });

  const onClose = () => {
    setIsDrawerOpen(false);
    setSelectedBuyerForEdit(false);
    reset({});
  };

  const handleSubmitForm = async (data) => {
    console.log(`document.activeElement.blur();`);
    if (defaultValues) {
      await putData(data, `buyers/${defaultValues.buyerId}`);
      showSuccessToast("Success", "Buyer updated successfully");
    } else {
      await postData(data, "buyer");
      showSuccessToast("Success", "Buyer added successfully");
    }
    fetchBuyersData(1, {});
    onClose();
  };

  return (
    <Drawer isOpen={true} placement="right" onClose={onClose} size="xl">
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton mt={4} />
          <Divider />
          <form onSubmit={handleSubmit(handleSubmitForm)}>
            <DrawerBody maxH={"89vh"}>
              <Box borderBottom="2px solid #628bb7" mb={3}>
                <DrawerHeader>
                  {defaultValues?.buyerId ? "Edit Buyer" : "New Buyer"}
                </DrawerHeader>
              </Box>
              <Text>Buyer Information</Text>
              <BoxWrapper>
                <Flex gap={5} flexWrap="wrap" mb={4}>
                  <FormControl
                    isInvalid={!!errors.category && !isSubmitting}
                    flex="1"
                  >
                    <CustomFormLabel color={"#191b1c"}>
                      Category
                    </CustomFormLabel>
                    <Controller
                      name="category"
                      control={control}
                      render={({ field }) => (
                        <Select {...field} placeholder="Select category">
                          <option value="manufacture">Manufacture</option>
                          <option value="dealer">Dealer</option>
                          <option value="other">Other</option>
                        </Select>
                      )}
                    />
                    <FormErrorMessage>
                      {errors.category && errors.category.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.buyerName} flex="1">
                    <CustomFormLabel color={"#191b1c"}>
                      Name
                    </CustomFormLabel>
                    <Controller
                      name="buyerName"
                      control={control}
                      render={({ field }) => (
                        <Input {...field} placeholder="Enter buyer name" />
                      )}
                    />
                    <FormErrorMessage>
                      {errors.buyerName && errors.buyerName.message}
                    </FormErrorMessage>
                  </FormControl>
                </Flex>
                <Flex gap={5} flexWrap="wrap" mb={4}>
                  <FormControl isInvalid={errors.contactNo} flex="1">
                    <CustomFormLabel color={"#191b1c"}>
                      Contact Number
                    </CustomFormLabel>
                    <Controller
                      name="contactNo"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          type="number"
                          placeholder="Enter contact number"
                        />
                      )}
                    />
                    <FormErrorMessage>
                      {errors.contactNo && errors.contactNo.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.emailId} flex="1">
                    <CustomFormLabel color={"#191b1c"}>Email</CustomFormLabel>
                    <Controller
                      name="emailId"
                      control={control}
                      render={({ field }) => (
                        <Input {...field} placeholder="Enter email" />
                      )}
                    />
                    <FormErrorMessage>
                      {errors.emailId && errors.emailId.message}
                    </FormErrorMessage>
                  </FormControl>
                </Flex>
                <Flex gap={5} flexWrap="wrap">
                  <FormControl isInvalid={errors.gstNo} flex="1">
                    <CustomFormLabel color={"#191b1c"}>
                      GST Number
                    </CustomFormLabel>
                    <Controller
                      name="gstNo"
                      control={control}
                      render={({ field }) => (
                        <Input {...field} placeholder="Enter GST number" />
                      )}
                    />
                    <FormErrorMessage>
                      {errors.gstNo && errors.gstNo.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.panNo} flex="1">
                    <CustomFormLabel color={"#191b1c"}>
                      PAN Number
                    </CustomFormLabel>
                    <Controller
                      name="panNo"
                      control={control}
                      render={({ field }) => (
                        <Input {...field} placeholder="Enter PAN number" />
                      )}
                    />
                    <FormErrorMessage>
                      {errors.panNo && errors.panNo.message}
                    </FormErrorMessage>
                  </FormControl>
                </Flex>
              </BoxWrapper>
              <BoxWrapper>
                <NewAddressDetails control={control} errors={errors} />
              </BoxWrapper>
            </DrawerBody>
            <DrawerFooter>
              <Button
                type="submit"
                colorScheme="blue"
                variant="solid"
                width={"200px"}
                mb={4}
                position={"absolute"}
                bottom={5}
                right={5}
                isDisabled={!isDirty || !isValid}
                onClick={() => document.activeElement.blur()}
              >
                {defaultValues?.buyerId ? "Update Buyer" : "Add Buyer"}
              </Button>
            </DrawerFooter>
          </form>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default BuyerDrawer;
