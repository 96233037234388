import React from 'react';
import { Box, Heading, Button } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
  return (
    <Box textAlign="center" mt={20}>
      <Heading as="h1" mb={8} fontSize="4xl" fontWeight="bold" color="red.500">
        Oops! Page Not Found
      </Heading>
      <Box mb={8}>
        <Heading as="h2" fontSize="xl" fontWeight="medium">
          The page you're looking for doesn't seem to exist.
        </Heading>
      </Box>
      <Button as={Link} to="/" colorScheme="blue" size="lg">
        Go to Homepage
      </Button>
    </Box>
  );
};

export default NotFoundPage;
