// validationSchemas.js

import * as Yup from 'yup';

// Custom method for GST number validation
Yup.addMethod(Yup.string, 'gst', function () {
  return this.test('gst', 'Invalid GST number', function (value) {
    if (!value) return true; // allow empty values if not mandatory
    const gstRegex = /^[0-9A-Z]{15}$/;
    return gstRegex.test(value);
  });
});

// Custom method for PAN number validation
Yup.addMethod(Yup.string, 'pan', function () {
  return this.test('pan', 'Invalid PAN number', function (value) {
    if (!value) return true; // allow empty values if not mandatory
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    return panRegex.test(value);
  });
});

Yup.addMethod(Yup.string, 'email', function () {
    return this.test('email', 'Invalid email address', function (value) {
      if (!value) return true; // allow empty values if not mandatory
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      return emailRegex.test(value);
    });
  });

// Function to create a schema for GST number with optional toggle
export const createGstSchema = (mandatory = false) =>
  Yup.string()
    .gst()
    .nullable(!mandatory)
    .label('GST Number')
    .when('$mandatory', {
      is: true,
      then: Yup.string().required('GST number is required'),
    });

// Function to create a schema for PAN number with optional toggle
export const createPanSchema = (mandatory = false) =>
  Yup.string()
    .pan()
    .nullable(!mandatory)
    .label('PAN Number')
    .when('$mandatory', {
      is: true,
      then: Yup.string().required('PAN number is required'),
    });

    export const createEmailSchema = (mandatory = false) =>
  Yup.string()
    .email()
    .nullable(!mandatory)
    .label('Email Address')
    .when('$mandatory', {
      is: true,
      then: Yup.string().required('Email address is required'),
    });

    export const pincodeSchema = Yup.string()
  .matches(/^\d{6}$/, 'Invalid pincode. Must be exactly 6 digits');