import { Button, Flex, Input } from "@chakra-ui/react";
import React from "react";

const TripSearchHeading = ({ setIsDrawerOpen }) => {
  return (
    <Flex justifyContent={"space-between"}>
      <Input disabled type="search" w={"40%"} placeholder="Search here" />
      <Button
        onClick={() => setIsDrawerOpen(true)}
        variant={"solid"}
        bg="blue.600"
        color="white"
        w="200px"
      >
        Add new Trip
      </Button>
    </Flex>
  );
};

export default TripSearchHeading;
