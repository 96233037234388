import React, { useState, useEffect } from "react";
import {
  Box,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Step,
  StepIndicator,
  StepSeparator,
  StepStatus,
  Stepper,
  Button,
  Flex,
  Text,
} from "@chakra-ui/react";
import TruckInfo from "./TruckInfo";
import OwnerDetails from "./OwnerDetails";
import AggregatorDetails from "./AggregatorDetails";
import DriverDetails from "./DriverDetails";
import { useGet, usePost, usePut } from "../../../hooks/api-call";
import useCustomToast from "../../../hooks/use-toast";
import "./drawer.css";

const STEPS = ["Truck Information", "Owner", "Aggregator", "Driver"];

// Component for displaying steps in the stepper
const StepperManager = ({ activeStep }) => {
  return (
    <Stepper size="lg" index={activeStep}>
      {STEPS.map((title, index) => (
        <Step key={index}>
          <StepIndicator
            bg={"gray.200"}
            w="150px"
            borderRadius={"5px"}
            border="none"
            borderBottom={activeStep > index && "1px solid #3436a8"}
            _hover={{ background: "#3182ce", color: "#fff" }}
            color={"gray"}
          >
            <StepStatus
              complete={<Text color={"#fff"}>{title}</Text>}
              incomplete={<Text>{title}</Text>}
              active={title}
            />
          </StepIndicator>
          <StepSeparator />
        </Step>
      ))}
    </Stepper>
  );
};

const TruckDrawer = ({
  setIsDrawerOpen,
  fetchTrucksData,
  selectedTruckForEdit,
  setSelectedTruckForEdit,
}) => {
  // Custom hook for handling API calls
  const { postData } = usePost();
  const { fetchData } = useGet();
  const { putData } = usePut();
  const { showErrorToast, showSuccessToast } = useCustomToast();
  const [random, setRandom] = useState(0);

  const [activeStep, setActiveStep] = useState(0);
  const [savedDataIds, setSavedDataIds] = useState({});
  const [formData, setFormData] = useState({
    truck: {},
    owner: {},
    aggregator: {},
    driver: {},
  });

  // Close the drawer
  const onClose = () => {
    setIsDrawerOpen(false);
    setSelectedTruckForEdit("");
    setActiveStep(0);
  };

  // Submit owner data
  const postOwnerData = async () =>
    selectedTruckForEdit || savedDataIds.ownerId
      ? await putData(
          formData.owner,
          `owners/${formData.owner.ownerId || savedDataIds.ownerId}`
        )
      : await postData(formData.owner, "owner");

  // Submit aggregator data
  const postAggregatorData = async () =>
    selectedTruckForEdit || savedDataIds.aggregatorId
      ? await putData(
          formData.aggregator,
          `aggregators/${
            formData.aggregator.aggregatorId || savedDataIds.aggregatorId
          }`
        )
      : await postData(formData.aggregator, "aggregator");

  // Submit driver data
  const postDriverData = async () =>
    selectedTruckForEdit
      ? await putData(formData.driver, `drivers/${formData.driver.driverId}`)
      : await postData(formData.driver, "driver");

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (activeStep === 0) {
      handleNextStep();
    }
    if (activeStep === 1) {
      try {
        const resp = await postOwnerData();
        setSavedDataIds((prev) => ({ ...prev, ownerId: resp }));
        handleNextStep();
      } catch (error) {
        showErrorToast("Error", error.response.data);
      }
    }
    if (activeStep === 2) {
      try {
        const resp = await postAggregatorData();
        setSavedDataIds((prev) => ({ ...prev, aggregatorId: resp }));
        handleNextStep();
      } catch (error) {
        showErrorToast("Error", error.response.data);
      }
    }

    if (activeStep === 3) {
      try {
        const resp = await postDriverData();
        handleAddTruck(resp);
      } catch (error) {
        showErrorToast("Error", error.response.data);
      }
    }
  };

  const handleAddTruck = async (driverId) => {
    try {
      const truckCopy = formData.truck;
      selectedTruckForEdit
        ? await putData(truckCopy, `trucks/${selectedTruckForEdit}`)
        : await postData(
            truckCopy,
            `truck?ownerId=${savedDataIds.ownerId}&driverId=${driverId}&aggregatorId=${savedDataIds.aggregatorId}`
          );
      onClose();
      fetchTrucksData(1);
      showSuccessToast(
        "Success",
        `Truck ${selectedTruckForEdit ? "updated" : "added"} successfully`
      );
    } catch (error) {
      console.log(`Truck error==>`, error);
    }
  };

  // Go to the next step
  const handleNextStep = () => {
    setActiveStep((prev) => prev + 1);
  };

  // Go to the previous step
  const handlePreviousStep = () => {
    setActiveStep((prev) => prev - 1);
  };

  // Handle form input changes
  const handleChange = (e, formName) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [formName]: {
        ...prevData[formName],
        [name]: value,
      },
    }));
  };

  // Effect for logging response data
  useEffect(() => {
    if (selectedTruckForEdit) {
      (async () => {
        const resp = await fetchData(`trucks/${selectedTruckForEdit}`);
        const { owner, driver, aggregator, ...rest } = resp;
        const finalData = {
          owner,
          driver,
          aggregator,
          truck: rest,
        };
        setFormData(finalData);
      })();
    }
  }, []);

  useEffect(() => {
    setRandom(Math.random(100));
  }, [formData]);

  return (
    <Drawer
      isOpen={
        true && selectedTruckForEdit
          ? Object.keys(formData?.truck).length > 0
          : true
      }
      placement="right"
      onClose={onClose}
      size="xl"
    >
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton mt={4} />

          <Divider />
          <DrawerBody>
            <form onSubmit={handleSubmit}>
              <Box borderBottom="2px solid #628bb7" mb={3}>
                <DrawerHeader pl={"18px"}>
                  {selectedTruckForEdit ? "Edit Truck" : "New Truck"}
                </DrawerHeader>
              </Box>
              <StepperManager activeStep={activeStep} />
              <Flex flexDirection="column" justifyContent={"space-between"}>
                <Box bg={"#f4f6f9"} p={4} mt={'24px'} borderRadius={"5px"}>
                  <Box
                    bg={"white"}
                    p={3}
                    borderRadius={"5px"}
                    maxH={"65vh"}
                    overflow={"scroll"}
                    pb={3}
                  >
                    {activeStep === 0 && (
                      <TruckInfo
                        activeStep={activeStep}
                        handleChange={handleChange}
                        initialValue={formData.truck}
                        isEditMode={!!selectedTruckForEdit}
                      />
                    )}
                    {activeStep === 1 && (
                      <OwnerDetails
                        activeStep={activeStep}
                        handleChange={handleChange}
                        initialValue={formData.owner}
                      />
                    )}
                    {activeStep === 2 && (
                      <AggregatorDetails
                        activeStep={activeStep}
                        handleChange={handleChange}
                        initialValue={formData.aggregator}
                      />
                    )}
                    {activeStep === 3 && (
                      <DriverDetails
                        activeStep={activeStep}
                        handleChange={handleChange}
                        initialValue={formData.driver}
                      />
                    )}
                  </Box>
                </Box>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  pt={3}
                  boxSizing="border-box"
                >
                  {activeStep > 0 ? (
                    <Button onClick={handlePreviousStep} mr={4}>
                      Back
                    </Button>
                  ): 
                  <Box></Box>
                  }
                  <Button colorScheme="blue" type="submit">
                    {activeStep === 3
                      ? selectedTruckForEdit
                        ? "Update"
                        : "Submit"
                      : "Next"}
                  </Button>
                </Box>
              </Flex>
            </form>
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default TruckDrawer;
