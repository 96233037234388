import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
} from "@chakra-ui/react";
import { FiPlus } from "react-icons/fi";

const BankDetails = ({ onChange, keyName, paymentDetails }) => {
  // Initial bank detail structure
  const initialBankDetail = {
    accountHolderName: "",
    bankName: "",
    accountNo: "",
    ifscCode: "",
    branchName: "",
    upiId: "",
    upiMobileHolderName: "",
  };

  // State to manage bank details
  const [bankDetails, setBankDetails] = useState(
    paymentDetails || [initialBankDetail]
  );

  // Handler to update bank details
  const handleBankDetails = (event, index) => {
    const { name, value } = event.target;
    setBankDetails((prevBankDetails) => {
      // Create a copy of the bank details array
      const updatedBankDetails = [...prevBankDetails];
      // Update the bank detail at the specified index
      updatedBankDetails[index] = {
        ...updatedBankDetails[index],
        [name]: value,
      };
      return updatedBankDetails;
    });
  };

  // Handler to add a new bank detail section
  const handleAddNewFormSection = () => {
    // Add a new bank detail section to the array
    setBankDetails((prev) => [...prev, initialBankDetail]);
  };

  // Effect to trigger onChange when bankDetails state changes
  useEffect(() => {
    onChange({ target: { name: keyName, value: bankDetails } });
  }, [bankDetails]);

  const handleRemove = (indexToDelete) => {
    const newArray = [...bankDetails];

    // Check if the index is valid
    if (indexToDelete < 0 || indexToDelete >= newArray.length) {
      throw new Error("Index is out of range");
    }

    // Remove the element at the specified index
    newArray.splice(indexToDelete, 1);
    setBankDetails(newArray);
  };

  return (
    <Box mt={"40px"}>
      <Flex justifyContent={"space-between"}>
        <Text color={"#9d9b9b"} mb={3}>
          Bank Details
        </Text>
        {/* Button to add a new bank detail section */}
        {/* <Button onClick={handleAddNewFormSection}> */}
        {/* <Button onClick={handleAddNewFormSection}>
          <FiPlus />
        </Button> */}
      </Flex>
      {/* Iterate over bank details array */}
      {bankDetails.map(
        (
          {
            accountHolderName,
            bankName,
            accountNo,
            ifscCode,
            branchName,
            upiId,
            upiMobileHolderName,
          },
          index
        ) => {
          return (
            <Flex key={index} justifyContent={"space-between"} mb="30px">
              {/* Index number */}
              <Box
                w="40px"
                h="40px"
                bg={"#464646"}
                borderRadius={"50%"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                color={"#fff"}
              >
                {index + 1}
              </Box>
              <Box w="93%">
                {/* Form fields */}
                <Flex justify="space-between" gap={3} mb={3}>
                  <FormControl isRequired>
                    <FormLabel color={"#191b1c"}>Account Holder Name</FormLabel>
                    <Input
                      name="accountHolderName"
                      type="text"
                      value={accountHolderName}
                      onChange={(e) => handleBankDetails(e, index)}
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel color={"#191b1c"}>Bank Name</FormLabel>
                    <Input
                      name="bankName"
                      type="text"
                      value={bankName}
                      onChange={(e) => handleBankDetails(e, index)}
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel color={"#191b1c"}>Account No</FormLabel>
                    <Input
                      name="accountNo"
                      type="number"
                      value={accountNo}
                      onChange={(e) => handleBankDetails(e, index)}
                    />
                  </FormControl>
                </Flex>
                <Flex justify="space-between" gap={3} mb={3}>
                  <FormControl isRequired>
                    <FormLabel color={"#191b1c"}>IFSC Code</FormLabel>
                    <Input
                      name="ifscCode"
                      type="text"
                      value={ifscCode}
                      onChange={(e) => handleBankDetails(e, index)}
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel color={"#191b1c"}>Branch Name</FormLabel>
                    <Input
                      name="branchName"
                      type="text"
                      value={branchName}
                      onChange={(e) => handleBankDetails(e, index)}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel color={"#191b1c"}>UPI Id</FormLabel>
                    <Input
                      name="upiId"
                      type="number"
                      value={upiId}
                      onChange={(e) => handleBankDetails(e, index)}
                    />
                  </FormControl>
                </Flex>
                <Flex justifyContent={"space-between"} alignItems={"center"}>
                  <FormControl mb={3}>
                    <FormLabel color={"#191b1c"}>
                      UPI Mobile Holder Name
                    </FormLabel>
                    <Input
                      w="50%"
                      name="upiMobileHolderName"
                      type="text"
                      value={upiMobileHolderName}
                      onChange={(e) => handleBankDetails(e, index)}
                    />
                  </FormControl>
                  {index > 0 && (
                    <Button
                      bg={"red"}
                      color={"#fff"}
                      onClick={() => handleRemove(index)}
                    >
                      Remove
                    </Button>
                  )}
                </Flex>
              </Box>
            </Flex>
          );
        }
      )}
    </Box>
  );
};

export default BankDetails;
