import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useRef } from "react";
import BankDetails from "./BankDetails";
import AddressDetails from "./AddressDetails";
import PANInput from "../../../atoms/PANInput";
import EmailInput from "../../../atoms/EmailInput";
import PhoneNumberInput from "../../../atoms/PhoneNumberInput";
import GSTInput from "../../../atoms/GSTNumber";

const AggregatorDetails = ({
  activeStep,
  handleChange,
  initialValue: {
    aggregatorType,
    firstName,
    fatherName,
    contactNo,
    emailId,
    panNo,
    gstNo,
    paymentDetails,
    ...rest
  },
}) => {
  const myRef = useRef(null);
  
  const handleFormChange = (e) => {
    handleChange(e, "aggregator");
  };

  const handleRadioButton = (e, name) => {
    handleFormChange({ target: { name, value: e } });
  };

  useEffect(()=>{
    myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  },[])

  return (
    <Box ref={myRef} display={activeStep === 2 ? "block" : "none"}>
      <Text color={"#9d9b9b"} mb={3}>
        Aggregator Details
      </Text>
      <Flex justify="space-between" gap={3} mb={3}>
        <FormControl>
          <FormLabel color={"#191b1c"}>Type</FormLabel>
          <RadioGroup
            defaultValue={aggregatorType || "firm"}
            name="aggregatorType"
            onChange={(e) => handleRadioButton(e, "aggregatorType")}
          >
            <Stack direction="row">
              <Radio value="firm">Firm</Radio>
              <Radio value="individual">Individual</Radio>
            </Stack>
          </RadioGroup>
        </FormControl>
        <FormControl isRequired>
          <FormLabel color={"#191b1c"}>Name of Firm/Owner</FormLabel>
          <Input
            name="firstName"
            value={firstName}
            type="text"
            onChange={handleFormChange}
          />
        </FormControl>
        <FormControl>
          <FormLabel color={"#191b1c"}>Father's Name</FormLabel>
          <Input
            name="fatherName"
            value={fatherName}
            type="text"
            onChange={handleFormChange}
          />
        </FormControl>
      </Flex>
      <Flex justify="space-between" gap={3} mb={3}>
        <FormControl isRequired>
          <FormLabel color={"#191b1c"}>Contact No.</FormLabel>
          <PhoneNumberInput
            name="contactNo"
            value={contactNo}
            type="number"
            onChange={handleFormChange}
          />
        </FormControl>
        <FormControl>
          <FormLabel color={"#191b1c"}>Email ID</FormLabel>
          <EmailInput
            name="emailId"
            value={emailId}
            type="text"
            onChange={handleFormChange}
          />
        </FormControl>
        <FormControl>
          <FormLabel color={"#191b1c"}>PAN No</FormLabel>
          <PANInput
            name="panNo"
            value={panNo}
            type="text"
            onChange={handleFormChange}
          />
        </FormControl>
      </Flex>
      <Flex justify="space-between" gap={3} mb={3}>
        <FormControl>
          <FormLabel color={"#191b1c"}>GST No</FormLabel>
          <GSTInput
            name="gstNo"
            value={gstNo}
            type="text"
            onChange={handleFormChange}
          />
        </FormControl>
        <></>
        <></>
      </Flex>

      <BankDetails
        onChange={handleFormChange}
        keyName="paymentDetails"
        paymentDetails={paymentDetails}
      />
      <AddressDetails onChange={handleFormChange} initialValue={rest} />
    </Box>
  );
};

export default AggregatorDetails;
