import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useGet } from "../../../hooks/api-call";
import CustomFormLabel from "../../../atoms/CustomFormLabel";
import { Controller } from "react-hook-form";

const OrderSellerSection = ({ onChange, initialValue, control, errors }) => {
  const [selectedSeller, setSelectedSeller] = useState(initialValue || {});

  const { responseData: sellers } = useGet(`sellers`);

  const handleChange = (seller) => {
    const modifiedValue = {
      target: {
        name: "sellerId",
        value: seller.sellerId,
      },
    };
    setSelectedSeller(seller);
    onChange(modifiedValue);
  };

  return (
    <Box>
      <Flex gap={2} align="end">
        <FormControl isInvalid={errors?.sellerId}>
          <CustomFormLabel isRequired color={"#191b1c"}>
            Mine Name
          </CustomFormLabel>
          <Controller
            name="sellerId"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                placeholder="Select seller"
                onChange={(e) => {
                  const seller = JSON.parse(e.target.value);
                  field.onChange(seller.sellerId); // set the sellerId in the form state
                  handleChange(seller); // handle other changes if necessary
                }}
                value={field.value}
              >
                {sellers &&
                  sellers.length > 0 &&
                  sellers.map((seller, index) => (
                    <option value={JSON.stringify(seller)} key={index}>
                      {seller?.mineName}
                    </option>
                  ))}
              </Select>
            )}
          />
          <FormErrorMessage>
            {errors.sellerId && errors.sellerId.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isRequired>
          <FormLabel color={"#191b1c"}>Company Name</FormLabel>
          <Input placeholder="" value={selectedSeller?.companyName} disabled />
        </FormControl>
        <FormControl>
          <FormLabel color={"#191b1c"}>GST No</FormLabel>
          <Input type="text" value={selectedSeller?.gstNo} disabled />
        </FormControl>
      </Flex>
      <Flex gap={5} mt={4}>
        <FormControl>
          <FormLabel color={"#191b1c"}>PAN No</FormLabel>
          <Input type="text" value={selectedSeller?.panNo} disabled />
        </FormControl>
        <FormControl>
          <FormLabel color={"#191b1c"}>Contact No.</FormLabel>
          <Input type="number" value={selectedSeller?.contactNo} disabled />
        </FormControl>
        <FormControl>
          <FormLabel color={"#191b1c"}>Email ID</FormLabel>
          <Input type="text" value={selectedSeller?.emailId} disabled />
        </FormControl>
      </Flex>
      <FormControl mt={4}>
        <FormLabel color={"#191b1c"}>Delivery Address</FormLabel>
        <Input
          type="text"
          value={
            selectedSeller.address
              ? `${(selectedSeller?.address || "") + ", "} ${
                  (selectedSeller?.locality || "") + ", "
                } ${(selectedSeller?.district || "") + ", "} ${
                  (selectedSeller?.state || "") + ", "
                } ${(selectedSeller?.pincode || "") + ", "}`
              : ""
          }
          disabled
        />
      </FormControl>
    </Box>
  );
};

export default OrderSellerSection;
