import React, { useEffect, useMemo, useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Box,
  Flex,
  Text,
} from "@chakra-ui/react";
import { FaEdit } from "react-icons/fa";
import { useDelete, useGet } from "../../../hooks/api-call";
import { convertMillisecondsToDate } from "../../../hooks/helper";
import { FiTrash2 } from "react-icons/fi";
import { BoxWrapper } from "./TripDrawer";
import useConfirmationDialog from "../../../hooks/use-confimation-dialog";

const ExpenseTable = ({
  editabeTripId,
  toBeUpdateUI,
  setExpenseId,
  handleExpense,
}) => {
  console.log(`toBeUpdateUI`, toBeUpdateUI);
  const [expenses, setExpenses] = useState({});
  const { deleteData } = useDelete();
  const { fetchData } = useGet(`trips/${editabeTripId}/expenses`, [
    toBeUpdateUI,
  ]);

  const { responseData: allTripsHistory } = useGet(
    `trip/${editabeTripId}/tripStages`,
    null,
    [toBeUpdateUI]
  );

  const handleRemoveExpense = async (expenseId) => {
    await deleteData(null, `trips/${editabeTripId}/expenses/${expenseId}`);
    callApi();
  };

  const { openConfirmationDialog, Dialog } =
    useConfirmationDialog(handleRemoveExpense);

  const callApi = async () => {
    const resp = await fetchData(`trips/${editabeTripId}/expenses`);
    setExpenses(resp);
  };

  useEffect(() => {
    callApi();
  }, [toBeUpdateUI]);

  const isDisabled = useMemo(()=>{
    return (!!allTripsHistory &&
      allTripsHistory.length > 0 &&
      allTripsHistory.some((s) => +s.stage.tripStageId === 9));
  },[allTripsHistory]);

  return (
    <Box>
      {Dialog}
      <BoxWrapper>
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Price Per ton</Th>
                <Th>Qty</Th>
                <Th isNumeric>Amount (INR)</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>{expenses?.pricePerTon} </Td>
                <Td>{expenses?.tripQuantity || 0} </Td>
                <Td isNumeric>{expenses?.totalAmount || "N/A"}</Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      </BoxWrapper>
      <BoxWrapper>
        {!isDisabled && (
            <Flex justifyContent={"end"}>
              <Text
                cursor={"pointer"}
                _hover={{ textDecoration: "underline" }}
                color="blue"
                onClick={handleExpense}
              >
                Add expense
              </Text>
            </Flex>
          )}
        <TableContainer mt={5}>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Expense</Th>
                <Th>Date</Th>
                <Th>Paytype</Th>
                <Th isNumeric>Amount (INR)</Th>
              </Tr>
            </Thead>
            <Tbody>
              {!!expenses &&
                !!expenses.tripExpense &&
                expenses.tripExpense.length > 0 &&
                expenses.tripExpense.map((expense, index) => {
                  return (
                    <Tr>
                      <Td>{expense?.paymentType?.name}</Td>
                      <Td>{convertMillisecondsToDate(expense?.createdDate)}</Td>
                      <Td>{expense?.payType?.name}</Td>
                      <Td isNumeric>{expense?.amount} </Td>
                      <Td>
                        <Flex justifyContent={"space-between"}>
                          <Box mr={3}>
                            <FaEdit
                              cursor={!isDisabled ? "pointer": 'default'}
                              onClick={() =>
                                !isDisabled && setExpenseId(expense?.tripExpenseId)
                              }
                            />{" "}
                          </Box>
                          <FiTrash2
                            color="red"
                            cursor={!isDisabled ? "pointer": 'default'}
                            onClick={() =>
                              !isDisabled &&  openConfirmationDialog(expense?.tripExpenseId)
                            }
                          />{" "}
                        </Flex>
                      </Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        </TableContainer>
      </BoxWrapper>

      <BoxWrapper>
        <TableContainer mt={5}>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th></Th>
                <Th>Percantage</Th>
                <Th>Qty</Th>
                <Th isNumeric>Amount (INR)</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>TDS</Td>
                <Td>{expenses?.tdsPercentage?.value || "N/A"} </Td>
                <Td>{expenses?.tripQuantity || 0} </Td>
                <Td isNumeric>{expenses?.tdsAmount || "N/A"}</Td>
              </Tr>
              <Tr>
                <Td>GST</Td>
                <Td>{expenses?.gstPercentage?.value || "N/A"}</Td>
                <Td>{expenses?.tripQuantity || 0} </Td>
                <Td isNumeric>{expenses?.gstAmount || "N/A"}</Td>
              </Tr>
              {/* <Tr>
              <Td>Final Payment</Td>
              <Td>{convertMillisecondsToDate(expenses?.createdDate)}</Td>
              <Td>{expenses?.tripQuantity || 0} </Td>
              <Td isNumeric>400.4</Td>
            </Tr> */}
            </Tbody>
          </Table>
        </TableContainer>
      </BoxWrapper>

      <BoxWrapper>
        <TableContainer mt={5}>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th></Th>
                <Th>Qty</Th>
                <Th isNumeric>Amount (INR)</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr bg={"#e2e2e2"}>
                <Td>
                  <strong>Balance Amount</strong>
                </Td>
                <Td>{expenses?.tripQuantity || 0} </Td>
                <Td isNumeric>{expenses?.balanceAmount || "N/A"}</Td>
              </Tr>
              <Tr bg={"#e2e2e2"}>
                <Td>
                  <strong>Final Amount</strong>
                </Td>
                <Td></Td>
                <Td isNumeric> {expenses?.finalPayment || "N/A"}</Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      </BoxWrapper>
    </Box>
  );
};

export default ExpenseTable;
