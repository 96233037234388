import React, { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { makeApiRequest } from "../hooks/helper";

const AuthContext = createContext();

const getAllScreens = async (token, setScreens) => {
  try {
    const headers = {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };
    const resp = await makeApiRequest(
      "GET",
      `user/screens`,
      null,
      headers || {}
    );
    setScreens(resp);
  } catch (error) {}
};

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState(null);
  const [screens, setScreens] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    try {
      const token = storedToken;
      getAllScreens(token, setScreens);
    } catch (error) {}
    if (storedToken) {
      // You might want to implement token validation here
      // For simplicity, let's assume the token is always valid
      setToken(storedToken);
      setIsAuthenticated(true);
    }
  }, [token]);

  const login = (token_1, rohan_value) => {
    setToken(token_1);
    localStorage.setItem("token", token_1);
  };

  const logout = () => {
    setToken(null);
    setIsAuthenticated(false);
    localStorage.removeItem("token");
    navigate("/login");
    setScreens(null);
    // You might want to redirect the user to the login page after logout
  };

  return (
    <AuthContext.Provider
      value={{ token, isAuthenticated, screens, login, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => React.useContext(AuthContext);
