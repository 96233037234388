import React from "react";
import { Avatar, Box, Divider, HStack, Heading, IconButton, Spacer } from "@chakra-ui/react";
import { FiLogOut, FiSearch } from "react-icons/fi";
import { useAuth } from "../auth/AuthProvider";

const DashboardHeader = ({ heading = "" }) => {

  const { logout } = useAuth();

  const handleLogout = () => {
    logout(); 
  }

  return (
    <Box boxShadow={'md'}>
      <HStack>
        <Heading fontSize={"2xl"} fontWeight={"light"} p="4">
          {heading}
        </Heading>
        <Spacer />
        {/* <IconButton icon={<FiSearch />} variant="ghost" /> */}

        <Avatar size="sm" />
        <IconButton onClick={handleLogout} icon={<FiLogOut />} variant="ghost" />
      </HStack>
      <Divider mb="5" />
    </Box>
  );
};

export default DashboardHeader;
