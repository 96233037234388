import { useState } from 'react';
import { Input, FormErrorMessage, Box } from '@chakra-ui/react';

const EmailInput = ({ name, value = '', onChange, isEditMode }) => {
  const [inputValue, setInputValue] = useState(value);
  const [isValid, setIsValid] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const [touched, setTocuched] = useState(false);

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setInputValue(newValue);

    // Email validation regex
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = regex.test(newValue);
    setIsValid(isValidEmail);

    if(isValidEmail){
      setTocuched(false);
    }

    onChange({ target: { name: event.target.name, value: newValue } });
  };

  const handleInputBlur = () => {
    setIsHovered(false);
    if (!isValid) {
      setIsValid(false);
    }
    if(!inputValue){
      setTocuched(false);
      setIsValid(true);
    }
  };

  const handleInputFocus = () => {
    setIsHovered(true);
    setTocuched(true);
  };

  return (
    <>
      <Input
        name={name}
        type="email"
        value={inputValue}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        onFocus={handleInputFocus}
        isInvalid={!isValid && !isHovered}
        disabled={isEditMode}
      />
     {!isValid && touched && !isHovered &&  (
        <Box color={'red'}>
          Please enter a valid email address
        </Box>
      )}
    </>
  );
};

export default EmailInput;
