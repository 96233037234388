import React from 'react'
import AdminOrderDashboard from '../admin-section/orders/OrderDashboard';
import BuyerOrderDashboard from '../buyer-section/orders/OrderDashboard';
import { useAuth } from '../auth/AuthProvider';

const RoleBasedOrders = () => {
   // Mocked user role; in real application, get it from authentication context or a similar state.
   const { screens } = useAuth();
   const userRole = screens?.roleName; // Example: 'admin', 'buyer', 'seller'

   const renderTrips = (role) => {
     switch (role.toLowerCase()) {
       case 'admin':
         return <AdminOrderDashboard />;
       case 'buyer':
         return <BuyerOrderDashboard />;
       default:
         return <div>Unauthorized</div>;
     }
   };
 
   return  renderTrips(userRole)
}

export default RoleBasedOrders