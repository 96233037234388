import { useState } from "react";
import { Input } from "@chakra-ui/react";

const NumberInput = ({ name, value, onChange, disabled, placeholder }) => {
  const [inputValue, setInputValue] = useState(value);

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    // Allow only numeric characters (0-9)
    const numericValue = newValue.replace(/\D/g, '');
    setInputValue(numericValue);

    setInputValue(numericValue);

    onChange({ target: { name: event.target.name, value: numericValue } });
  };

  return (
    <Input
      name={name}
      type="text"
      onChange={handleInputChange}
      value={inputValue}
      disabled={disabled}
      placeholder={placeholder}
    />
  );
};

export default NumberInput;
