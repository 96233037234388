import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Heading,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { BoxWrapper } from "./TripDrawer";
import { PiTruckFill } from "react-icons/pi";
import { FaUserSecret } from "react-icons/fa";
import { useGet } from "../../../hooks/api-call";
import EditSubSectionTrip from "./EditSubSectionTrip";
import TruckHistory from "./TruckHistory";
import TripExpense from "./TripExpense";
import BasicUsage from "./BasicUsage";
import ExpenseTable from "./ExpenseTable";
import { convertMillisecondsToDate } from "../../../hooks/helper";

const AccordionLabel = ({ icon, label }) => {
  return (
    <h2>
      <AccordionButton display={"flex"} justifyContent={"space-between"} px={0}>
        <Box
          bg={"#edf1fa"}
          // width={"fit-content"}
          py={3}
          pr={3}
          borderTopRightRadius={"10px"}
          borderBottomRightRadius={"10px"}
          minW={"250px"}
        >
          <Flex as="span" flex="1" textAlign="left" ml={2}>
            {icon}
            <Heading as="h4" size="md" color="#f97769" ml={3}>
              {label}
            </Heading>
          </Flex>
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
  );
};

const TextLabel = ({ title, value }) => {
  return (
    <Box>
      <Text color="rgba(157, 155, 155, 1)" fontSize={"14px"}>
        {title}
      </Text>
      <Text color="rgba(44, 45, 91, 1)" fontWeight={"700"} fontSize={"16px"}>
        {value}
      </Text>
    </Box>
  );
};

const EditTripDrawer = ({ editabeTripId, onClose }) => {
  const [tripData, setTripData] = useState();
  const [openExpenseDrawer, setOpenExpenseDrawer] = useState(false);
  const [refreshHistory, setRefreshHistory] = useState(false);
  const [truckHistory, setTruckHistory] = useState([]);
  const [toBeUpdateUI, setToBeUpdateUI] = useState();
  const [expenseId, setExpenseId] = useState("");

  const { fetchData } = useGet();

  // Effect for logging response data
  useEffect(() => {
    if (editabeTripId) {
      (async () => {
        const resp = await fetchData(`trips/${editabeTripId}`);
        setTripData(resp);
      })();
    }
  }, []);

  const handleExpense = () => {
    setOpenExpenseDrawer(true);
  };

  return (
    <Drawer isOpen={true} placement="right" onClose={onClose} size="2xl">
      <DrawerOverlay>
        <DrawerContent>
        <DrawerCloseButton mt={1} />

          <Divider />
          <DrawerBody display={"flex"}>
            <Box width={"50%"}>
              <Flex>
                <Heading as="h4" size="lg">
                  Trip Id: {editabeTripId}
                </Heading>
                <Button variant="ghost" ml={3}>
                  <FiEdit color="blue" />
                </Button>
              </Flex>
              <Box>
                <BoxWrapper>
                  <Accordion allowMultiple>
                    <AccordionItem>
                      <AccordionLabel
                        icon={<PiTruckFill fontSize={"26px"} color="#f97769" />}
                        label="Trip Information"
                      />
                      <AccordionPanel pb={4}>
                        <Flex justifyContent={"space-between"}>
                          <TextLabel
                            title="Truck No."
                            value={tripData?.truck?.truckNumber}
                          />
                          <TextLabel
                            title="Payment to."
                            value={tripData?.paymentTo}
                          />
                          <TextLabel
                            title="Driver Name"
                            value={
                              tripData?.truck?.driver?.firstName +
                              " " +
                              (tripData?.truck?.driver?.middleName || "") +
                              " " +
                              (tripData?.truck?.driver?.lastName || "")
                            }
                          />
                          <TextLabel
                            title="Price per ton."
                            value={tripData?.pricePerTon}
                          />
                        </Flex>
                        <Flex mt={5} justifyContent={"space-between"}>
                          <TextLabel
                            title="Address."
                            value={
                              (tripData?.order?.buyer?.address || "") +
                              ", " +
                              (tripData?.order?.buyer?.tehsil || "") +
                              ", " +
                              (tripData?.order?.buyer?.state || "") +
                              ", " +
                              (tripData?.order?.buyer?.pincode || "")
                            }
                          />
                           <TextLabel
                            title="Weight (in tons)"
                            value={tripData?.tripQuantity}
                          />
                           <TextLabel
                            title="Ravana No"
                            value={tripData?.ravanaNo}
                          />
                           <TextLabel
                            title="Created date"
                            value={convertMillisecondsToDate(tripData?.createdDate)}
                          />
                        </Flex>
                      </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionLabel
                        icon={<PiTruckFill fontSize={"26px"} color="#f97769" />}
                        label="Order Information"
                      />
                      <AccordionPanel pb={4}>
                        <Flex justifyContent={"space-between"}>
                          <TextLabel
                            title="Order Id"
                            value={tripData?.order?.orderId}
                          />
                          <TextLabel
                            title="Po Number."
                            value={tripData?.order?.poNumber}
                          />
                          <TextLabel
                            title="Status"
                            value={tripData?.order?.orderStatus}
                          />
                          <TextLabel
                            title="Total qunatity."
                            value={tripData?.order?.totalQuantity}
                          />
                        </Flex>
                        <Flex mt={3} gap={20}>
                          <TextLabel
                            title="Material"
                            value={tripData?.order?.material?.materialName}
                          />
                           <TextLabel
                            title="Created Date"
                            value={convertMillisecondsToDate(tripData?.order?.createdDate)}
                          />
                        </Flex>
                      </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionLabel
                        icon={
                          <FaUserSecret fontSize={"26px"} color="#f97769" />
                        }
                        label="Owner Information"
                      />
                      <AccordionPanel pb={4}>
                        <Flex justifyContent={"space-between"}>
                          <TextLabel
                            title="Name"
                            value={tripData?.truck?.owner?.firstName}
                          />
                          <TextLabel
                            title="Contact no."
                            value={tripData?.truck?.owner?.contactNo}
                          />
                          <TextLabel
                            title="Email Id"
                            value={tripData?.truck?.owner?.emailId}
                          />
                        </Flex>
                        <Flex mt={3}>
                          <TextLabel
                            title="Address."
                            value={
                              (tripData?.order?.owner?.address || "") +
                              ", " +
                              (tripData?.order?.owner?.tehsil || "") +
                              ", " +
                              (tripData?.order?.owner?.state || "") +
                              ", " +
                              (tripData?.order?.owner?.pincode || "")
                            }
                          />
                        </Flex>
                      </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionLabel
                        icon={
                          <FaUserSecret fontSize={"26px"} color="#f97769" />
                        }
                        label="Buyer Information"
                      />
                      <AccordionPanel pb={4}>
                        <Flex justifyContent={"space-between"}>
                          <TextLabel
                            title="Name"
                            value={tripData?.truck?.owner?.firstName}
                          />
                          <TextLabel
                            title="Contact no."
                            value={tripData?.truck?.owner?.contactNo}
                          />
                          <TextLabel
                            title="Email Id"
                            value={tripData?.truck?.owner?.emailId}
                          />
                        </Flex>
                        <Flex mt={3}>
                          <TextLabel
                            title="Unloading Address."
                            value={
                              (tripData?.order?.buyer?.address || "") +
                              ", " +
                              (tripData?.order?.buyer?.tehsil || "") +
                              ", " +
                              (tripData?.order?.buyer?.state || "") +
                              ", " +
                              (tripData?.order?.buyer?.pincode || "")
                            }
                          />
                        </Flex>
                      </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionLabel
                        icon={
                          <FaUserSecret fontSize={"26px"} color="#f97769" />
                        }
                        label="Expenses"
                      />
                      <AccordionPanel pb={4}>
                        <ExpenseTable
                          setExpenseId={(value) => {
                            setExpenseId(value);
                            setOpenExpenseDrawer(true);
                          }}
                          toBeUpdateUI={toBeUpdateUI}
                          editabeTripId={editabeTripId}
                          handleExpense={handleExpense}
                        />
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                </BoxWrapper>
              </Box>
            </Box>
            <Box w={"50%"} mt={10} ml={3}>
              {openExpenseDrawer && (
                <BasicUsage
                  expenseId={expenseId}
                  editabeTripId={editabeTripId}
                  truckHistory={truckHistory}
                  onClose={() => {
                    setToBeUpdateUI(Math.random() * 2030303);
                    setOpenExpenseDrawer(false);
                    setExpenseId("");
                  }}
                />
              )}
              <EditSubSectionTrip
                truckHistory={truckHistory}
                setRefreshHistory={setRefreshHistory}
                editabeTripId={editabeTripId}
                setToBeUpdateUI={()=>setToBeUpdateUI(Math.random() * 2030303)}
              />
              <TruckHistory
                setRefreshHistory={setRefreshHistory}
                setTruckHistory={setTruckHistory}
                refreshHistory={refreshHistory}
                editabeTripId={editabeTripId}
              />
            </Box>
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default EditTripDrawer;
