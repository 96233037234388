import { useState } from 'react';
import { Input, FormErrorMessage, Box } from '@chakra-ui/react';

const PhoneNumberInput = (props) => {
  const { name, value = '', onChange, isEditMode } = props;
  const [inputValue, setInputValue] = useState(value);
  const [isValid, setIsValid] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const [touched, setTocuched] = useState(false);


  const handleInputChange = (event) => {
    let newValue = event.target.value;
    // Allow only numeric characters (0-9) and "+" symbol
    const numericValue = newValue.replace(/[^0-9+]/g, '');
    // Limit to 12 characters including "+"
    const limitedValue = numericValue.slice(0, 10);
    setInputValue(limitedValue);

    // Indian phone number format: +91 followed by 10 digits
    const regex = /^[0-9]{10}$/;
    const isValidPhoneNumber = regex.test(limitedValue);
    setIsValid(isValidPhoneNumber);
    if(isValidPhoneNumber){
      setTocuched(false);
    }

    onChange({ target: { name: event.target.name, value: limitedValue } });
  };

  const handleInputBlur = () => {
    setIsHovered(false);
    if (inputValue.length !== 10) {
      setIsValid(false);
    }
    if(!inputValue){
      setTocuched(false);
      setIsValid(true);
    }
  };

  const handleInputFocus = () => {
    setIsHovered(true);
    setTocuched(true);
  };

  return (
    <>
      <Input
        name={name}
        type="tel"
        value={inputValue}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        onFocus={handleInputFocus}
        isInvalid={!isValid && !isHovered}
        disabled={isEditMode}
      />
      {!isValid && touched && !isHovered &&  (
        <Box color={'red'}>
           Please enter a valid Indian phone number
        </Box>
      )}
    </>
  );
};

export default PhoneNumberInput;
