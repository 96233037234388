// components/RoleBasedDashboard.jsx
import React from 'react';
import AdminTripDashBoard from '../admin-section/trips/TripDashboard';
import BuyerTripDashBoard from '../buyer-section/trips/TripDashboard';
import { useAuth } from '../auth/AuthProvider';

const RolesBasedTrips = () => {
  // Mocked user role; in real application, get it from authentication context or a similar state.
  const { screens } = useAuth();
  const userRole = screens?.roleName; // Example: 'admin', 'buyer', 'seller'

  const renderTrips = (role) => {
    switch (role.toLowerCase()) {
      case 'admin':
        return <AdminTripDashBoard />;
      case 'buyer':
        return <BuyerTripDashBoard />;
      case 'seller':
        return <AdminTripDashBoard />;
      default:
        return <div>Unauthorized</div>;
    }
  };

  return  renderTrips(userRole)
};

export default RolesBasedTrips;
