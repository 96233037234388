import React, { useCallback } from 'react';

export const useYupValidationResolver = (validationSchema) =>
  useCallback(
    async (data) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });

        return {
          values,
          errors: {},
        };
      } catch (validationErrors) {
        if (validationErrors.inner) {
          return {
            values: {},
            errors: validationErrors.inner.reduce(
              (allErrors, currentError) => ({
                ...allErrors,
                [currentError.path]: {
                  type: currentError.type ?? 'validation',
                  message: currentError.message,
                },
              }),
              {}
            ),
          };
        } else {
          return {
            values: {},
            errors: {
              [validationErrors.path]: {
                type: 'validation',
                message: validationErrors.message,
              },
            },
          };
        }
      }
    },
    [validationSchema]
  );
