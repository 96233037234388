import React from "react";
import { Outlet } from "react-router-dom";
import { useAuth } from "../auth/AuthProvider";

// Dummy authentication function, you should replace it with your actual authentication logic
const isAuthenticated = () => {
  // Check if the user is authenticated, e.g., by verifying if a token is present
  const token = localStorage.getItem("token");
  return !!token; // !! converts value to boolean
};

const PublicRoute = ({ element, children, ...rest }) => {
  // Your authentication logic goes here
  const isAuth = isAuthenticated();
  const { screens } = useAuth();

  const accessPages =
    !!screens &&
    screens.access &&
    screens.access.length > 0 &&
    screens.access.map(({ route }) => {
      return route;
    });

  if (!!isAuth && accessPages) {
    window.location.href = accessPages[0];
    return;
  }

  return <React.Fragment>{children ?? <Outlet />}</React.Fragment>;
};

export default PublicRoute;
