import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Spinner,
  Tab,
  TabList,
  Table,
  TableContainer,
  Tabs,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { FiEdit, FiEye, FiTrash2 } from "react-icons/fi";
import useConfirmationDialog from "../../hooks/use-confimation-dialog";

const TripListing = ({
  isLoading,
  trips,
  handleEditOpen,
  handleRemoveTrip,
  activePage,
  setActivePage,
  totalPages,
  fetchTripsData,
  setSelectedTabStatus,
  seletctedTabStatus,
  stageWiseCounts,
}) => {
  const [selectedIndex, setSelectedIndex] = useState("all");
  const { openConfirmationDialog, Dialog } =
    useConfirmationDialog(handleRemoveTrip);

  const handleNextPage = () => {
    if (totalPages > activePage + 1) {
      const page = activePage + 1;
      setActivePage(page);
      fetchTripsData(page);
    }
  };

  const handlePrevPage = () => {
    if (activePage > 0) {
      const page = activePage - 1;
      setActivePage(page);
      fetchTripsData(page + "");
    }
  };

  const handleTabClick = (id) => {
    setSelectedIndex(id);
    setSelectedTabStatus(id);
  };

  useEffect(() => {
    if (seletctedTabStatus === "all") {
      setSelectedIndex(seletctedTabStatus);
    } else {
      setSelectedIndex(+seletctedTabStatus);
    }
  }, [seletctedTabStatus]);

  return (
    <>
      {Dialog}
      <Box overflowX={"scroll"}>
        <Tabs
          index={+seletctedTabStatus || 0}
          defaultIndex={+seletctedTabStatus || 0}
          mt="5"
          width={"1730px"}
          sx={{
            "::-webkit-scrollbar": {
              display: "none",
            },
            "-ms-overflow-style": "none" /* IE and Edge */,
            "scrollbar-width": "none" /* Firefox */,
          }}
        >
          <TabList>
            {!!stageWiseCounts &&
              stageWiseCounts.length > 0 &&
              [
                {
                  id: "all",
                  stageCount: stageWiseCounts.reduce(
                    (acc, curr) => acc + curr.stageCount,
                    0
                  ),
                  stageName: "All",
                },
                ...stageWiseCounts,
              ]
                .sort((a, b) => a.id - b.id)
                .map((m, index) => {
                  return (
                    <Tab
                      onClick={() => handleTabClick(m?.id)}
                      color={selectedIndex === m?.id ? "blue" : "gray.500"}
                    >
                      {m?.stageName} ({m?.stageCount})
                    </Tab>
                  );
                })}
          </TabList>
        </Tabs>
      </Box>
      <TableContainer
        w="100%"
        borderRadius={"md"}
        border="1px"
        borderColor="gray.200"
        bg="whiteAlpha.600"
        maxHeight={"60vh"}
        overflowY="scroll"
      >
        {isLoading ? (
          <Box
            h={"60vh"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Spinner size="xl" />
          </Box>
        ) : (
          <Table>
            <Thead bg="blackAlpha.50">
              <Tr>
                {/* <Th>PO No</Th> */}
                <Th>PO No</Th>
                <Th>Buyer Name</Th>
                <Th>Truck No</Th>
                {/* <Th>Eway Bill No</Th> */}
                {/* <Th>Driver Name</Th> */}
                <Th>Material</Th>
                <Th>Qty</Th>
                <Th>Delivery address</Th>
                <Th>Trip status</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {trips?.map((trip) => (
                <Tr key={trip?.tripId}>
                  <Td>{trip?.order?.poNumber}</Td>
                  <Td>{trip?.order?.buyer?.buyerName}</Td>
                  <Td>{trip?.truck?.truckNumber}</Td>
                  <Td>{trip?.order?.material?.materialName}</Td>
                  <Td>{trip?.tripQuantity}</Td>
                  {trip?.order?.buyer?.address ? (
                    <Td>
                      {trip?.order?.buyer?.address},{" "}
                      {trip?.order?.buyer?.locality} - (
                      {!!trip?.order?.buyer?.pincode
                        ? trip?.order?.buyer?.pincode
                        : ""}
                      ){" "}
                    </Td>
                  ) : (
                    <Td></Td>
                  )}
                  <Td>{trip?.latestTripStage?.stageName}</Td>
                  <Td>
                    <Button
                      variant="ghost"
                      onClick={() => handleEditOpen(trip.tripId)}
                    >
                      <FiEye color="blue" />
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
      </TableContainer>
      <Box style={{ textAlign: "right" }} w="100%" px="5" my="3" >
        <Button disabled={activePage === 0} onClick={handlePrevPage} width={'150px'}>
          Previous
        </Button>
        <span style={{ margin: "0 0.5rem" }}>
          Page {activePage + 1} of {totalPages}
        </span>
        <Button disabled={totalPages === activePage} onClick={handleNextPage} width={'150px'}>
          Next
        </Button>
      </Box>
    </>
  );
};

export default TripListing;
