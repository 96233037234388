import { Box } from "@chakra-ui/react";
import React, { useState } from "react";
import { useDelete, useGet } from "../../hooks/api-call";
import OrderListing from "./OrderListing";
import OrderSearchHeading from "./OrderSearchHeading";
import OrderFilters from "./OrderFilters";
import OrderDrawer from "./drawer-forms/OrderDrawer";
import useCustomToast from "../../hooks/use-toast";
import { buildURLWithQueryParams } from "../../hooks/helper";

const AdminOrderDashboard = () => {
  const [activePage, setActivePage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedOrderForEdit, setSelectedOrderForEdit] = useState(false);
  
  const { isLoading, responseData, fetchData } = useGet(`orders/search`);
  const { responseData: orderStageCount, fetchData: fetchStageWiseQuantity } = useGet(`orders/stageWiseQuantity`, [
    isDrawerOpen
  ]);
  const { responseData: minerals } = useGet(`minerals`);

  const { deleteData } = useDelete();
  const { showSuccessToast } = useCustomToast();

  const fetchOrdersData = async (currentPage, details) => {
    
    const url = buildURLWithQueryParams("orders/search", {
      page: currentPage || activePage,
      size: pageSize,
      ...details,
    });
    await fetchData(url);

    const stageUrl = buildURLWithQueryParams("orders/stageWiseQuantity", {
      ...details,
    });
    
    await fetchStageWiseQuantity(stageUrl);

  };

  const handleRemoveOrder = (orderId) => {
    deleteData(null, `orders/${orderId}`);
    showSuccessToast("Success", `Order deleted successfully`);
    fetchOrdersData(activePage);
  };

  const handleEditOpen = (order) => {
    setIsDrawerOpen(true);
    setSelectedOrderForEdit(order);
  };

  return (
    <Box bg="gray.50">
      <Box pl="5" pr="5">
        <OrderSearchHeading setIsDrawerOpen={setIsDrawerOpen} />
        <OrderFilters fetchOrdersData={fetchOrdersData} />
        <OrderListing
          handleCreateNewOrder={() => setIsDrawerOpen(true)}
          handleRemoveOrder={handleRemoveOrder}
          isLoading={isLoading}
          orders={responseData?.content}
          activePage={activePage}
          setActivePage={setActivePage}
          pageSize={pageSize}
          totalPages={Math.ceil(
            (responseData?.numberOfElements || 0) / pageSize
          )}
          fetchTrucksData={fetchOrdersData}
          handleEditOpen={handleEditOpen}
          orderStageCount={orderStageCount}
        />
      </Box>

      {isDrawerOpen && !!minerals && minerals.length > 0 && (
        <OrderDrawer
          setSelectedOrderForEdit={setSelectedOrderForEdit}
          selectedOrderForEdit={selectedOrderForEdit}
          fetchOrdersData={fetchOrdersData}
          setIsDrawerOpen={setIsDrawerOpen}
          minerals={minerals}
        />
      )}
    </Box>
  );
};

export default AdminOrderDashboard;
