import React, { useState } from "react";
import { Box, Flex, Link, Text, Input, Divider } from "@chakra-ui/react";

import {
  FiGrid,
  FiSettings,
  FiShoppingCart,
  FiTruck,
  FiMapPin,
  FiUsers,
  FiUser,
} from "react-icons/fi";
import { useAuth } from "../auth/AuthProvider";
import { useLocation } from "react-router-dom";

const ActiveLink = ({ isActive, href, children }) => {
  return (
    <Link
      display="flex"
      alignItems="center"
      bg={isActive && "#fff"}
      href={href}
      color={isActive && "#246fae"}
      borderRadius={"5px"}
      pl={2}
    >
      {children}
    </Link>
  );
};

const LoggedNavigation = ({ accessPages }) => {
  const location = useLocation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleToggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const { logout } = useAuth();

  const handleLogout = () => {
    logout();
  };

  function isActiveRoute(currentLocation, route) {
    return currentLocation.pathname === route;
  }

  return (
    <Box>
      {/* Left Column */}
      <Box
        overflow="hidden"
        transition="width 0.3s"
        minH="100vh"
        maxH="100vh"
        h="100%"
        bg="blue.600"
        borderRight="2px solid #e1e1e1"
        color="white"
        position="relative"
        flex="1"
      >
        {/* Logo and Navigation Links */}
        <Box mt="5" m="5" p="5" textAlign="center">
          <Text fontSize="xl" fontWeight="bold">
            Logo here
          </Text>
        </Box>

        {/* Navigation Links with Icons */}
        <Flex
          direction="column"
          align="stretch"
          p={1}
          lineHeight={"10"}
          fontSize={"18"}
        >
          <ActiveLink isActive={isActiveRoute(location, "/dashboard")}  href="/dashboard">
            <FiGrid /> <Text ml="2">Dashboard</Text>
          </ActiveLink>
          {!!accessPages && accessPages.includes("order") && (
            <ActiveLink
              isActive={isActiveRoute(location, "/order")}
              href={`/order`}
            >
              <FiShoppingCart /> <Text ml="2">Orders</Text>
            </ActiveLink>
          )}

          {!!accessPages && accessPages.includes("truck") && (
            <ActiveLink
              isActive={isActiveRoute(location, "/truck")}
              href="/truck"
            >
              <FiTruck /> <Text ml="2">Trucks</Text>
            </ActiveLink>
          )}
          {!!accessPages && accessPages.includes("trip") && (
            <ActiveLink
              isActive={isActiveRoute(location, "/trip")}
              href="/trip"
            >
              <FiMapPin /> <Text ml="2">Trips</Text>
            </ActiveLink>
          )}
          {!!accessPages && accessPages.includes("sellers") && (
            <ActiveLink
              isActive={isActiveRoute(location, "/sellers")}
              href="/sellers"
            >
              <FiUsers /> <Text ml="2">Sellers</Text>
            </ActiveLink>
          )}

          {!!accessPages && accessPages.includes("buyer") && (
            <ActiveLink
              isActive={isActiveRoute(location, "/buyer")}
              href="/buyer"
            >
              <FiUser /> <Text ml="2">Buyers</Text>
            </ActiveLink>
          )}
        </Flex>

        <Box
          bottom="0"
          p="3"
          position={"absolute"}
          lineHeight={"10"}
          fontSize={"18"}
          w="100%"
        >
          {/* <Link display="flex" alignItems="center">
            <FiUser /> <Text ml="2">User Profile</Text>
          </Link>
          <Divider mb="0" />
          <Link display="flex" alignItems="center">
            <FiSettings /> <Text ml="2">Settings</Text>
          </Link>
          <Divider mb="0" />
          <Box
            onClick={handleLogout}
            display="flex"
            alignItems="center"
            cursor={"pointer"}
          >
            <FiSettings /> <Text ml="2">Logout </Text>
          </Box> */}
          <ActiveLink
            isActive={isActiveRoute(location, "/feedback")}
            href="/feedback"
          >
            <FiUser /> <Text ml="2">Feedback</Text>
          </ActiveLink>
          <Flex alignItems={"center"}>
            <FiSettings /> <Text ml="2">Help/Support </Text>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};

export default LoggedNavigation;
