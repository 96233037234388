import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { BoxWrapper } from "./TripDrawer";
import { useGet, usePost } from "../../../hooks/api-call";
import { useEffect } from "react";
import useCustomToast from "../../../hooks/use-toast";

const EditSubSectionTrip = ({
  editabeTripId,
  setRefreshHistory,
  truckHistory,
  setToBeUpdateUI,
}) => {
  const { responseData: tripStages } = useGet(`possibleTripStages`);
  const { responseData: existingTripStages } = useGet(
    `trip/${editabeTripId}/tripStages`
  );
  const [selectedTripStage, setSelectedTripStage] = useState(0);
  const [weight, setWeight] = useState("");
  const [ravanaNo, setRavanaNo] = useState("");
  const [finalPayment, setFinalPayment] = useState("");
  const [disabledStatus, setDisabledStatus] = useState(false);

  const { postData } = usePost();
  const { showSuccessToast, showErrorToast } = useCustomToast();

  const handleUpdateTrip = async () => {
    const resp = {
      ravanaNumber: ravanaNo,
      finalPayment: finalPayment,
    };

    if(weight){
      resp['weightAtThisStage'] = weight;
    }

    try {
      await postData(
        resp,
        `trip/${editabeTripId}/tripStages/${selectedTripStage?.stageSeqId}`
      );
      showSuccessToast("Success", `Trip status updated successfully`);
      setRefreshHistory((p) => !p);
      setToBeUpdateUI();
    } catch (error) {
      showErrorToast("Error", error?.response?.data);
    }
  };

  useEffect(() => {
    setWeight("");
    setRavanaNo("");
  }, [selectedTripStage]);

  useEffect(() => {
    if (
      tripStages &&
      tripStages.length > 0 &&
      truckHistory &&
      !!truckHistory.length
    ) {
      const item = truckHistory.sort().pop();
      const resp = tripStages.filter((f) => f.stageSeqId == item + 1);
      if (!!resp.length) {
        if (
          +resp[0]?.stageSeqId === 5 &&
          !!existingTripStages &&
          existingTripStages.length > 0
        ) {
          setTimeout(() => {
            setWeight(existingTripStages[0]?.weightAtThisStage);
            setRavanaNo(existingTripStages[0]?.ravanaNumber);
          }, 200);
        }
        setSelectedTripStage(resp[0]);
        setDisabledStatus(false);
      } else {
        const items = tripStages.filter((f) => f.stageSeqId == item);
        setSelectedTripStage(items[0]);
        setDisabledStatus(true);
      }
    }
  }, [tripStages, truckHistory]);

  return (
    <Box>
      <BoxWrapper>
        <Flex justifyContent={"space-between"}>
          <Box>
            <Text color={"rgba(5, 30, 92, 1)"} fontSize={"20px"}>
              Trip Stage
            </Text>
            <Text color={"rgba(166, 166, 166, 1)"}>
              Select the value to update Truck's journey
            </Text>
          </Box>
          <Text color={"blue"}>Generate Bilty</Text>
        </Flex>
        <Flex mt={3}>
          <Select
            name="stageSeqId"
            onChange={(e) => setSelectedTripStage(JSON.parse(e.target.value))}
            value={selectedTripStage?.stageSeqId}
            disabled={disabledStatus}
          >
            {selectedTripStage?.stageName && (
              <option>{selectedTripStage?.stageName}</option>
            )}
            {/* {tripStages &&
              tripStages.length > 0 &&
              tripStages.map((tripStage, index) => {
                return (
                  <option
                    key={tripStage?.stageSeqId}
                    value={JSON.stringify(tripStage)}
                    disabled={truckHistory.includes(tripStage?.stageSeqId)}
                  >
                    {tripStage?.stageName}
                  </option>
                );
              })} */}
          </Select>
          <Button
            colorScheme="blue"
            w={"200px"}
            disabled={disabledStatus}
            cursor={disabledStatus ? "default" : "pointer"}
            ml={2}
            onClick={() => !disabledStatus && handleUpdateTrip()}
          >
            Update
          </Button>
        </Flex>

        {(selectedTripStage?.stageSeqId == 5 ||
          selectedTripStage?.stageSeqId == 8) && (
          <Flex gap={5} mt={4}>
            <FormControl isRequired={selectedTripStage?.stageSeqId == 8}>
              <FormLabel color={"#191b1c"}>Weight</FormLabel>
              <Input
                name="weight"
                type="text"
                onChange={(e) => setWeight(e.target.value)}
                value={weight}
              />
            </FormControl>
            {selectedTripStage?.stageSeqId != 8 && (
              <FormControl>
                <FormLabel color={"#191b1c"}>Ravana No</FormLabel>
                <Input
                  name="ravanaNo"
                  type="text"
                  value={ravanaNo}
                  onChange={(e) => setRavanaNo(e.target.value)}
                />
              </FormControl>
            )}
          </Flex>
        )}
        {selectedTripStage?.stageSeqId == 9 && (
          <Flex mt={3}>
            <FormControl isRequired={selectedTripStage?.stageSeqId == 8}>
              <FormLabel color={"#191b1c"}>Amount (INR)</FormLabel>
              <Input
                disabled={disabledStatus}
                name="finalPayment"
                type="text"
                value={finalPayment}
                onChange={(e) => setFinalPayment(e.target.value)}
              />
            </FormControl>
          </Flex>
        )}
      </BoxWrapper>
    </Box>
  );
};

export default EditSubSectionTrip;
