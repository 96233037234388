import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import LoggedNavigation from "../Navigation/LoggedNavigation";
import { Box, Button, Grid, GridItem, Heading, Text } from "@chakra-ui/react";
import { useAuth } from "../auth/AuthProvider";
import { useLocation } from "react-router-dom";
import DashboardHeader from "./DashboardHeader";

function pluralize(word) {
  const exceptions = {
    trip: "Trips",
    order: "Orders",
    buyer: "Buyers",
    seller: "Sellers",
    truck: "Trucks",
    dashboard: "Dashboards",
  };

  return exceptions[word.toLowerCase()] || word + "s";
}

// Dummy authentication function, you should replace it with your actual authentication logic
const isAuthenticated = () => {
  // Check if the user is authenticated, e.g., by verifying if a token is present
  const token = localStorage.getItem("token");
  return !!token; // !! converts value to boolean
};

const PrivateRoute = ({ element, children, ...rest }) => {
  const { screens } = useAuth();
  const location = useLocation();
  const path = location.pathname.split("/").pop();
  const accessPages =
    !!screens &&
    screens.access &&
    screens.access.length > 0 &&
    screens.access.map(({ route }) => {
      return route;
    });
  const navigate = useNavigate();
  // Your authentication logic goes here
  const isAuth = isAuthenticated();
  // if (!isAuth) {
  //   window.location.href = "/login";
  //   return;
  // }

  if (!accessPages) return <h1>Loading....</h1>;

  return (
    <React.Fragment>
      <Grid
        templateColumns="repeat(7, 1fr)" // Three columns of equal width
      >
        <GridItem colSpan={1} bg="#eef2f5">
          <LoggedNavigation accessPages={accessPages} />
        </GridItem>
        {accessPages.includes(path) ? (
          <GridItem
            paddingBottom={8}
            maxH={"100vh"}
            overflowY={"scroll"}
            colSpan={6}
            bg="#eef2f5"
          >
            <DashboardHeader heading={`${pluralize(path)} Management`} />
            {children ?? <Outlet />}
          </GridItem>
        ) : (
          <GridItem
            paddingBottom={8}
            maxH={"100vh"}
            overflowY={"scroll"}
            colSpan={6}
            bg="#eef2f5"
          >
            <DashboardHeader heading="Not Authorized" />
            <Box textAlign="center" py={10} px={6}>
              <Heading as="h2" size="xl" mt={6} mb={2}>
                Not Authorized
              </Heading>
              <Text color={"gray.500"}>
                You are not authorized to view this page.
              </Text>
              {/* <Button
                mt={3}
                colorScheme="teal"
                onClick={() => navigate("/login")}
              >
                Go to Login
              </Button> */}
            </Box>
          </GridItem>
        )}
      </Grid>
    </React.Fragment>
  );
};

export default PrivateRoute;
