import React, { useEffect, useState } from "react";
import {
  Box,
  Center,
  Stack,
  Text,
  Input,
  Button,
  Checkbox,
  SimpleGrid,
  Heading,
  useToast,
  InputGroup,
  IconButton,
  InputRightElement,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom"; // Import useHistory hook for navigation

import axios from "axios"; // Import axios if not already imported
import { useAuth } from "../auth/AuthProvider";
import { FaEye, FaEyeSlash } from "react-icons/fa";

export default function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const toast = useToast();
  const { login, token, screens } = useAuth(); // Get login function from AuthContext
  const navigate = useNavigate();

  const handleLogin = async () => {
    if (!username || !password) {
      // Display error message if username or password is empty
      toast({
        title: "Error",
        description: "Please enter both username and password.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } else {
      try {
        // Send login request to the server
        // const response = await axios.post("https://service.lb.prod.mwmlogistic.in/login", {
        const response = await axios.post("https://mwmlogistic.in/login", {
          userName: username,
          password: password,
        });

        if (response.status === 200) {
          // Upon successful login, update the authentication state and token
          login(response.data, "124"); // Assuming the token is returned from the server as "token"
          // Redirect to the dashboard
          // navigate("/truck");
        } else {
          // Display error message for invalid credentials
          toast({
            title: "Error",
            description: "Invalid credentials. Please try again.",
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "bottom-right",
          });
        }
      } catch (error) {
        // Display error message for server errors
        console.error("Error:", error);
        toast({
          title: "Error",
          description: "An error occurred. Please try again later.",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "bottom-right",
        });
      }
    }
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  useEffect(() => {
    if(token){
      const accessPages =
        !!screens &&
        screens.access &&
        screens.access.length > 0 &&
        screens.access.map(({ route }) => {
          return route;
        });
      if (accessPages) {
        navigate(`/${accessPages[0]}`);
      }
    }
  }, [screens]);

  return (
    <>
      (
        <Box border="0px" bg="gray.50" minH={"100vh"}>
          <Center p="5"></Center>
          <Center>
            <Stack>
              <Heading align="center" fontWeight={"regular"}>
                Login into your Account
              </Heading>
              <Text textAlign="center">
                Don't have an account? <a href="#">Signup</a>
              </Text>
            </Stack>
          </Center>
          <Center>
            <Stack
              mt="5"
              rounded={"xl"}
              w="md"
              p="4"
              border="0px"
              shadow="xl"
              lineHeight="10"
            >
              <Box as="form" mt={10} border="0px">
                <Stack spacing={4}>
                  <Box>
                    <Text>User Name</Text>
                    <Input
                      placeholder="User Name"
                      bg={"gray.100"}
                      border={0}
                      color={"gray.500"}
                      _placeholder={{
                        color: "gray.500",
                      }}
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      onKeyPress={handleKeyPress}
                    />
                  </Box>
                  <Box>
                    <Text>Password</Text>
                    <InputGroup>
                      <Input
                        pr="4.5rem"
                        type={showPassword ? "text" : "password"}
                        placeholder="Enter password"
                        onChange={(e) => setPassword(e.target.value)}
                        bg={"gray.100"}
                        border={0}
                        color={"gray.500"}
                        value={password}
                        _placeholder={{
                          color: "gray.500",
                        }}
                        onKeyPress={handleKeyPress}
                      />
                      <InputRightElement width="2.5rem">
                        <IconButton
                          h="1.75rem"
                          size="sm"
                          onClick={handleTogglePassword}
                          icon={showPassword ? <FaEyeSlash /> : <FaEye />}
                        />
                      </InputRightElement>
                    </InputGroup>
                  </Box>
                  <Box>
                    <SimpleGrid columns={2}>
                      <Checkbox onChange={() => setRememberMe(!rememberMe)}>
                        Remember me
                      </Checkbox>
                    </SimpleGrid>
                  </Box>
                  <Button
                    bg={"facebook.500"}
                    color="white"
                    onClick={handleLogin}
                  >
                    Login
                  </Button>
                  <Box>
                    <SimpleGrid columns={2}>
                      <a href="#">Forgot Password</a>
                    </SimpleGrid>
                  </Box>
                </Stack>
              </Box>
            </Stack>
          </Center>
        </Box>
      )
    </>
  );
}
