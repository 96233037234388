import { Button, Flex, Input } from "@chakra-ui/react";
import React from "react";

const TripSearchHeading = () => {
  return (
    <Flex justifyContent={"space-between"}>
      <Input disabled type="search" w={"40%"} placeholder="Search here" />
    </Flex>
  );
};

export default TripSearchHeading;
