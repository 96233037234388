import {
  Box,
  Button,
  HStack,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import {
  FiChevronLeft,
  FiChevronRight,
  FiEdit,
  FiTrash2,
} from "react-icons/fi";
import useConfirmationDialog from "../../hooks/use-confimation-dialog";

const BuyerListing = ({
  isLoading,
  buyers,
  handleEditOpen,
  handleRemoveTruck,
  activePage,
  setActivePage,
  totalPages,
  fetchBuyersData,
}) => {
  const { openConfirmationDialog, Dialog } =
    useConfirmationDialog(handleRemoveTruck);

  const handleNextPage = () => {
    if (totalPages > activePage) {
      const page = activePage + 1;
      setActivePage(page);
      fetchBuyersData(page);
    }
  };

  const handlePrevPage = () => {
    if (activePage > 1) {
      const page = activePage - 1;
      setActivePage(page);
      fetchBuyersData(page);
    }
  };

  return (
    <>
      {Dialog}
      <TableContainer
        w="100%"
        borderRadius={"md"}
        border="1px"
        borderColor="gray.200"
        bg="whiteAlpha.600"
        mt="6"
      >
        {isLoading ? (
          <Box
            h={"60vh"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Spinner size="xl" />
          </Box>
        ) : (
          <Table>
            <Thead bg="blackAlpha.50">
              <Tr>
                <Th>Category</Th>
                <Th>Name</Th>
                {/* <Th>GST No</Th>
                <Th>PAN No</Th>
                <Th>TAN No</Th> */}
                <Th>Contact No</Th>
                <Th>Ordered Qty</Th>
                <Th>Delivered Qty</Th>
                <Th>Address</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {buyers?.map((buyer) => (
                <Tr key={buyer?.buyerId}>
                  <Td>
                    <HStack>
                      <Text color="blackAlpha.600">
                        {buyer.category}
                      </Text>
                    </HStack>
                  </Td>
                  <Td>{buyer?.buyerName}</Td>
                  {/* <Td>{buyer?.gstNo}</Td> */}
                  {/* <Td> {`${buyer.panNo}`} </Td> */}

                  {/* <Td>{buyer?.tanNo} </Td> */}
                  <Td>{buyer?.contactNo} </Td>
                  <Td>{buyer?.orderedQuantity} </Td>
                  <Td>{buyer?.deliveredQuantity} </Td>
                  <Td>{buyer?.address }, {buyer?.locality} - ({!!buyer.pincode ? buyer.pincode : ''}) </Td>
                  <Td>
                    <Button
                      variant="ghost"
                      onClick={() => handleEditOpen(buyer)}
                    >
                      <FiEdit color="blue" />
                    </Button>
                    <Button
                      variant="ghost"
                      onClick={() => openConfirmationDialog(buyer.buyerId)}
                    >
                      <FiTrash2 color="red" />
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
      </TableContainer>
      <Box style={{ textAlign: "right" }} w="100%" px="5" mt="3">
        <Button disabled={activePage < 2} onClick={handlePrevPage} width={'150px'}>
          Previous
        </Button>
        <span style={{ margin: "0 0.5rem" }}>
          Page {activePage} of {totalPages}
        </span>
        <Button disabled={totalPages === activePage} onClick={handleNextPage} width={'150px'}>
         Next
        </Button>
      </Box>
    </>
  );
};

export default BuyerListing;
