import { Button, Flex, Input } from '@chakra-ui/react'
import React from 'react'

const BuyerSearchHeading = ({setIsDrawerOpen}) => {
  return (
    <Flex justifyContent={"space-between"}>
      <Input disabled type="search" w={"40%"} placeholder="Search buyer" />
      <Button
        onClick={() => setIsDrawerOpen(true)}
        variant={"solid"}
        bg="blue.600"
        color="white"
        mr={4}
        w="200px"
        _hover={{
          backgroundColor: "#054180",
        }}
      >
        Add new Buyer
      </Button>
    </Flex>
  )
}

export default BuyerSearchHeading