import React, {  useState } from "react";
import { BoxWrapper } from "./TripDrawer";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Textarea,
} from "@chakra-ui/react";
import NumberInput from "../../../atoms/NumberInput";
import { useGet, usePost, usePut } from "../../../hooks/api-call";
import useCustomToast from "../../../hooks/use-toast";

const TripExpense = ({
  editabeTripId,
  onClose,
  expenseId,
  initialFormData,
}) => {
  const [formData, setFormData] = useState(initialFormData || {});
  const [selectedFile, setSelectedFile] = useState(null);
  const { showSuccessToast } = useCustomToast();
  const { putData } = usePut();

  const { postData } = usePost();
  const { responseData: paymentTypes } = useGet("paymentTypes");
  const { responseData: payTypes } = useGet("payTypes");

  const handleFormChange = ({ target: { name, value } }) => {
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    handleFormChange(event);
  };

  const handleFileDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    setSelectedFile(file);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const resp = {
      ...formData,
      paymentType: paymentTypes.filter(
        (f) => f.paymentTypeId == formData?.paymentType
      )[0],
      payType: payTypes.filter((f) => f.payTypeId == formData?.payType)[0] || {
        "payTypeId": 1,
        "name": "cash"
    },
    };
    expenseId
      ? await putData(resp, `trips/${editabeTripId}/expenses/${expenseId}`)
      : await postData(resp, `trips/${editabeTripId}/expenses`);
    onClose();
    showSuccessToast(
      "Success",
      `Expense ${expenseId ? "updated" : "added"} successfully`
    );
  };

  const handleRadioButton = (e, name) => {
    handleFormChange({ target: { name, value: e } });
  };

  return (
    <form onSubmit={handleSubmit}>
      {
        <BoxWrapper>
          <Flex gap={5}>
            <FormControl isRequired>
              <FormLabel color={"#191b1c"}>Payment Type</FormLabel>
              <Select
                name="paymentType"
                onChange={handleFormChange}
                value={formData?.paymentType}
                placeholder={"Choose payment type"}
              >
                {!!paymentTypes &&
                  paymentTypes.length > 0 &&
                  paymentTypes.map((paymentType, index) => {
                    return (
                      <option key={index} value={paymentType?.paymentTypeId}>
                        {paymentType?.name}
                      </option>
                    );
                  })}
              </Select>
            </FormControl>
            <FormControl isRequired>
              <FormLabel color={"#191b1c"}>Amount</FormLabel>
              <NumberInput
                name={"amount"}
                value={formData?.amount}
                onChange={handleFormChange}
              />
            </FormControl>
          </Flex>
          <Flex gap={5} mt={4} mb={4}>
            <FormControl isRequired>
              <FormLabel color={"#191b1c"}>Payment Mode</FormLabel>
              <RadioGroup
                defaultValue={1}
                name="payType"
                value={+formData?.payType || 1}
                onChange={(e) => handleRadioButton(e, "payType")}
              >
                <Stack direction="row">
                  {!!payTypes &&
                    payTypes.length > 0 &&
                    payTypes.map((payType, index) => (
                      <Radio key={index} value={payType?.payTypeId}>
                        {payType?.name}
                      </Radio>
                    ))}
                </Stack>
              </RadioGroup>
            </FormControl>
          </Flex>
          <FormControl>
            <FormLabel color={"#191b1c"}>Notes</FormLabel>
            <Textarea
              type="text"
              name={"notes"}
              value={formData?.notes}
              onChange={handleFormChange}
            />
          </FormControl>
          {/* <FormControl mt={5}>
            <Center
              w="full"
              h="120px"
              borderWidth="1px"
              borderStyle="dashed"
              borderColor="blue.200"
              borderRadius="md"
              position="relative"
              onDrop={handleFileDrop}
              onDragOver={handleDragOver}
            >
              {selectedFile ? (
                <Text>{selectedFile.name}</Text>
              ) : (
                <>
                  <input
                    type="file"
                    id="truckDocuments"
                    name="truckDocuments"
                    accept=".pdf,.doc,.docx"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    disabled
                  />
                  <label htmlFor="truckDocuments">
                    <Flex alignItems={"center"}>
                      <Upload color="blue" size={30} />
                      <Box ml={2}>
                        <Text fontSize="xl" color={"blue"}>
                          Upload Document slip
                        </Text>
                        <Text>Select from system and drag and drop here</Text>
                      </Box>
                    </Flex>
                  </label>
                </>
              )}
            </Center>
          </FormControl> */}
          <Flex justifyContent={"flex-end"} mt={5}>
            <Box>
              <Button type="submit" colorScheme="blue">
                {expenseId ? "Update" : "Submit"}
              </Button>
            </Box>
          </Flex>
        </BoxWrapper>
      }
    </form>
  );
};

export default TripExpense;
