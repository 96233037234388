import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Select,
  Text,
} from "@chakra-ui/react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { useGet } from "../../hooks/api-call";
import NumberInput from "../../atoms/NumberInput";
import { useLocation, useNavigate } from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const TripFilters = ({
  fetchTripsData,
  seletctedTabStatus,
  setSelectedTabStatus,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { responseData: buyers } = useGet(`buyers`);
  const { responseData: tripStages } = useGet(`possibleTripStages`);
  const { responseData: poNumbers } = useGet(`activeOrderIds`);
  const { responseData: trucks } = useGet(`trucks`);

  const query = useQuery();
  const selectedOrder = query.get("selectedOrder");
  const navigate = useNavigate();

  const removeQUery = () => {
    if (selectedOrder) {
      const currentPath = window.location.pathname;
      const newQuery = new URLSearchParams(query);
      newQuery.delete("selectedOrder");
      navigate(`${currentPath}?${newQuery.toString()}`, { replace: true });
    }
  };

  const [filters, setFilters] = useState({
    buyerId: "",
    mineral: "",
    tripStatus: "",
    quantity: "",
  });

  const handleChange = ({ target: { name, value } }) => {
    removeQUery();
    setFilters((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleFilters = (data) => {
    fetchTripsData(
      "0",
      data || {
        ...filters,
        tripStatus:
          filters?.tripStatus ||
          (seletctedTabStatus != "all" ? seletctedTabStatus : ""),
      }
    );
  };

  const handleClearFilters = () => {
    const data = { buyerId: "", mineral: "", tripStatus: "", quantity: "" };
    setFilters(data);
    setTimeout(() => handleFilters(data), 100);
    setSelectedTabStatus("all");
  };

  const handleOpenFilter = () => {
    setIsOpen((prev) => {
      const element = document.getElementById("trips-filter-section");
      if (prev) {
        element.style.height = "0px";
        setFilters({
          buyerId: "",
          mineral: "",
          tripStatus: "",
          quantity: "",
        });
      } else {
        setTimeout(() => {
          element.style.height = element.scrollHeight + "px";
        });
      }
      return !prev;
    });
  };

  return (
    <Box
    mt={5}
    bg={"#fff"}
    p={4}
    boxSizing="border-box"
    borderRadius={"5px"}
    boxShadow={"base"}
    >
      <Flex
        alignItems={"center"}
        cursor={"pointer"}
        style={{ userSelect: "none" }}
        onClick={handleOpenFilter}
        mb={isOpen ? 2 : 0}
      >
        {isOpen ? <BsChevronUp /> : <BsChevronDown />}
        <Text ml={2} fontWeight={"bold"}>
          Filter
        </Text>
      </Flex>
      <Box
        id="trips-filter-section"
        h={0}
        transition={"height 0.4s ease-out"}
        overflow={"hidden"}
        bg={"#eef2f5"}
        p={isOpen && 2}
        borderRadius={5}
      >
        <Flex gap={5}>
          <FormControl>
            <FormLabel color="gray.600">Po number</FormLabel>
            <Select
              name="orderId"
              onChange={handleChange}
              value={filters.orderId}
              placeholder={"Select po number"}
            >
              {poNumbers &&
                poNumbers.length > 0 &&
                poNumbers.map((poNumber, index) => {
                  return (
                    <option key={poNumber.orderId} value={poNumber.orderId}>
                      {poNumber?.poNumber}
                    </option>
                  );
                })}
            </Select>
          </FormControl>

          <FormControl>
            <FormLabel color="gray.600">Buyers</FormLabel>
            <Select
              name="buyerId"
              value={filters.buyerId}
              placeholder="Select Buyer"
              onChange={handleChange}
            >
              {buyers &&
                buyers.length > 0 &&
                buyers.map((buyer, index) => {
                  return (
                    <option value={buyer.buyerId} key={index}>
                      {buyer?.buyerName}
                    </option>
                  );
                })}
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel color="gray.600">Trip status</FormLabel>
            <Select
              name="tripStatus"
              onChange={handleChange}
              value={filters?.tripStatus || seletctedTabStatus}
              placeholder={"Choose option"}
            >
              {tripStages &&
                tripStages.length > 0 &&
                tripStages.map((tripStage) => {
                  return (
                    <option
                      key={tripStage?.tripStageId}
                      value={tripStage?.tripStageId}
                    >
                      {tripStage?.stageName}{" "}
                    </option>
                  );
                })}
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel color="gray.600">Trucks</FormLabel>
            <Select
              name="truckId"
              onChange={handleChange}
              value={filters?.truckId}
              placeholder={"Choose option"}
            >
              {trucks &&
                trucks.length > 0 &&
                trucks.map((truck) => {
                  return (
                    <option
                      key={truck?.truckId}
                      value={truck?.truckId}
                    >
                      {truck?.truckNumber}{" "}
                    </option>
                  );
                })}
            </Select>
          </FormControl>
        </Flex>
        <Flex justifyContent={"flex-end"} mt={3}>
          <Button
            onClick={handleClearFilters}
            colorScheme="gray"
            w="100px"
            mr={"10px"}
          >
            Clear all
          </Button>
          <Button
            onClick={() => {
              handleFilters();
              setSelectedTabStatus(filters?.tripStatus || "all");
            }}
            variant={"solid"}
            bg="blue.600"
            color="white"
            w="200px"
          >
            Search
          </Button>
        </Flex>
      </Box>
    </Box>
  );
};

export default TripFilters;
