import React, { useState, useCallback, useEffect, useRef } from "react";
import { Box, Input, Text, VStack, CircularProgress } from "@chakra-ui/react";
import { useGet } from "../../../hooks/api-call";

const CustomAsyncSelect = ({ handleChange, onChange, value, ...props }) => {
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { fetchData } = useGet();
  const containerRef = useRef(null);

  // Debounce function to delay API calls
  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func.apply(this, args), delay);
    };
  };

  // Debounced fetchOptions function
  const debouncedFetchOptions = useCallback(
    debounce(async (inputValue) => {
      setIsLoading(true);
      const resp = await fetchData(
        `trucks/truckNumber?truckNumber=${inputValue}`
      );
      setOptions(resp); // Assuming response is an array of options
      setIsLoading(false);
    }, 500), // Adjust the delay as needed
    []
  );

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    debouncedFetchOptions(newValue);
    setIsOpen(true);
  };

  useEffect(() => {
    debouncedFetchOptions("");
  }, []);

  const handleOptionClick = (option) => {
    setInputValue(`${option.truckNumber}`);
    handleChange({ target: { name: "truckId", value: option } });
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <VStack
      alignItems="flex-start"
      spacing={4}
      ref={containerRef}
      position={"relative"}
    >
      <Input
        {...props}
        value={inputValue}
        onChange={handleInputChange}
        onClick={() => setIsOpen(true)}
        placeholder="Type to search..."
      />
      {isOpen && (
        <Box
          boxShadow="dark-lg"
          boxSizing="border-box"
          w="full"
          zIndex={10}
          maxH={"300px"}
          overflowY={"scroll"}
          mt={-2}
          position={"absolute"}
          top={"60px"}
        >
          {isLoading && <CircularProgress isIndeterminate color="green.300" />}
          {options.map((option) => (
            <Box
              key={option.truckId}
              bg="gray.200"
              px={3}
              py={4}
              borderBottom="1px solid #fff"
              onClick={() => handleOptionClick(option)}
              cursor={"pointer"}
              _hover={{
                bg: "gray.300", // Change background color on hover
              }}
            >
              <Text>{option?.truckNumber}</Text>
            </Box>
          ))}
        </Box>
      )}
    </VStack>
  );
};

export default CustomAsyncSelect;
