import {
  Box,
  Flex,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  Text,
  useSteps,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { BoxWrapper } from "./TripDrawer";
import { User } from "react-feather";
import { useGet, usePut } from "../../../hooks/api-call";
import useContinueConfirmationDialog from "../../../hooks/use-continue-confirmation-dialog";
import useCustomToast from "../../../hooks/use-toast";
import { mergeTripDetails } from "../../../hooks/helper";

const TruckHistory = ({
  editabeTripId,
  refreshHistory,
  setTruckHistory,
  setRefreshHistory,
}) => {
  const { showSuccessToast } = useCustomToast();
  const { responseData: tripStages } = useGet(`possibleTripStages`);

  const handleUndoLastStage = async () => {
    await putData({}, `/trip/${editabeTripId}/tripStages/`);
    setRefreshHistory((p) => !p);
    showSuccessToast("Success", `Undo last stage successfully`);
  };

  const { openConfirmationDialog, Dialog } =
    useContinueConfirmationDialog(handleUndoLastStage);
  const { putData } = usePut();
  const { responseData: allTripsHistory } = useGet(
    `trip/${editabeTripId}/tripStages`,
    null,
    [refreshHistory]
  );

  useEffect(() => {
    !!allTripsHistory &&
      !!allTripsHistory.length > 0 &&
      setTruckHistory(allTripsHistory.map((m) => m.stage.tripStageId));
  }, [allTripsHistory]);

  return (
    <BoxWrapper>
      <Box h="95vh">
      {Dialog}
      <Flex mb={7} alignItems={"center"} justifyContent={"space-between"}>
        <Text fontSize={"20px"} fontWeight={"600"}>
          Truck‘s Journey
        </Text>
      </Flex>
      <Stepper
        index={allTripsHistory?.length}
        orientation="vertical"
        height="85vh"
        gap="0"
        colorScheme="green"
      >
        {tripStages &&
          tripStages.length > 0 && !!allTripsHistory?.length &&
          mergeTripDetails(tripStages,allTripsHistory).map((step, index) => (
            <Step key={step?.tripTrackerId} width={"100%"}>
              <StepIndicator>
                <StepStatus
                  complete={<StepIcon />}
                  incomplete={<StepNumber color="#babfc6"/>}
                  active={<StepNumber color="#babfc6" />}
                />
              </StepIndicator>

              <Box width={"100%"}>
                <Flex
                  h="30px"
                  min-width={"100%"}
                  bg={"rgba(244, 246, 249, 1)"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  p="5px 10px"
                  borderRadius={"5px"}
                >
                  <StepTitle>{step?.stageName}</StepTitle>
                  {!!step?.weightAtThisStage && (
                    <Text>W: {step?.weightAtThisStage}</Text>
                  )}
                  {!!step?.ravanaNumber && (
                    <Text>R No: {step?.ravanaNumber}</Text>
                  )}
                 {step?.updatedAt && <Flex>
                    <User />
                    <Text mr={4} ml={2}>
                      {step?.user}
                    </Text>
                    <Text color={"grey"}> {step?.updatedAt}</Text>
                  </Flex>}
                </Flex>
              </Box>

              <StepSeparator h="30px" />
            </Step>
          ))}
      </Stepper>
      </Box>
    </BoxWrapper>
  );
};

export default TruckHistory;
