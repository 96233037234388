import React from "react";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  VStack,
  HStack,
  IconButton,
  Box,
} from "@chakra-ui/react";
import { FaStar } from "react-icons/fa";
import DashboardHeader from "../../atoms/DashboardHeader";
import { BoxWrapper } from "../../admin-section/trips/drawer-contents/TripDrawer";
import { useYupValidationResolver } from "../../hooks/use-validation-resolver";

const schema = yup.object().shape({
  name: yup.string().required("Name is required"),
  rating: yup.number().required("Rating is required").min(1, "Rating must be at least 1"),
  comment: yup.string().required("Comment is required"),
});

const Feedback = () => {
  const resolver = useYupValidationResolver(schema);
  const { handleSubmit, control, formState: { errors, isValid }, setValue, watch } = useForm({
    resolver,
    mode: "onBlur",
    defaultValues: {
      name: "",
      rating: 0,
      comment: "",
    },
  });

  const rating = watch('rating');

  const onSubmit = (data) => {
    console.log(data); // Handle submission logic here
  };

  return (
    <Box bg="gray.50">
      <DashboardHeader heading="Feedback" />
      <Box p={4} maxWidth="600px">
        <BoxWrapper>
          <form onSubmit={handleSubmit(onSubmit)}>
            <VStack spacing={4} align="stretch">
              <FormControl id="name" isInvalid={!!errors.name}>
                <FormLabel>Name</FormLabel>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => <Input {...field} />}
                />
                {errors.name && (
                  <Box color="red.500" fontSize="sm">
                    {errors.name.message}
                  </Box>
                )}
              </FormControl>

              <FormControl id="rating" isInvalid={!!errors.rating}>
                <FormLabel>Rating</FormLabel>
                <HStack>
                  {[...Array(5)].map((_, index) => (
                    <IconButton
                      key={index}
                      icon={<FaStar />}
                      onClick={() => setValue('rating', index + 1)}
                      colorScheme={index < rating ? "yellow" : "gray"}
                      variant="ghost"
                    />
                  ))}
                </HStack>
                {errors.rating && (
                  <Box color="red.500" fontSize="sm">
                    {errors.rating.message}
                  </Box>
                )}
              </FormControl>

              <FormControl id="comment" isInvalid={!!errors.comment}>
                <FormLabel>Comment</FormLabel>
                <Controller
                  name="comment"
                  control={control}
                  render={({ field }) => (
                    <Textarea {...field} height="200px" />
                  )}
                />
                {errors.comment && (
                  <Box color="red.500" fontSize="sm">
                    {errors.comment.message}
                  </Box>
                )}
              </FormControl>

              <Button
                type="submit"
                colorScheme="blue"
                width={"200px"}
                isDisabled={!isValid}
              >
                Submit
              </Button>
            </VStack>
          </form>
        </BoxWrapper>
      </Box>
    </Box>
  );
};

export default Feedback;
