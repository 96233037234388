import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import TripExpense from "./TripExpense";
import { useGet } from "../../../hooks/api-call";
import { useEffect, useState } from "react";

function BasicUsage({ onClose, editabeTripId, expenseId }) {
  const [formData, setFormData] = useState({});
  const { fetchData } = useGet();

  const callAPi = async () => {
    const resp = await fetchData(
      `trips/${editabeTripId}/expenses/${expenseId}`
    );
    if (resp) {
      setFormData({
        ...resp,
        payType: resp?.payType?.payTypeId,
        paymentType: resp?.paymentType?.paymentTypeId,
      });
    }
  };

  useEffect(() => {
    callAPi();
  }, [expenseId]);

  return (
    <>
      <Modal
        size="xl"
        blockScrollOnMount={false}
        isOpen={expenseId ? Object.keys(formData).length > 0 : true}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Expenses</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <TripExpense
              initialFormData={formData}
              expenseId={expenseId}
              onClose={onClose}
              editabeTripId={editabeTripId}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default BasicUsage;
