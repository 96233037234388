import React from "react";
import { useAuth } from "../auth/AuthProvider";
import AdminDashboard from "../admin-section/dashboard";
import BuyerDashboard from "../buyer-section/dashboard";
import SellerDashboard from "../seller-section/dashboard";

const RoleBasedDashboard = () => {
  // Mocked user role; in real application, get it from authentication context or a similar state.
  const { screens } = useAuth();
  const userRole = screens?.roleName; // Example: 'admin', 'buyer', 'seller'

  const renderTrips = (role) => {
    switch (role.toLowerCase()) {
      case "admin":
        return <AdminDashboard />;
      case "buyer":
        return <BuyerDashboard />;
      case "seller":
        return <SellerDashboard />;
      default:
        return <div>Unauthorized</div>;
    }
  };

  return renderTrips(userRole);
};

export default RoleBasedDashboard;
