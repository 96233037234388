// hoc/withAuthorization.js
import React from "react";
import { Box, Heading, Text, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import {useAuth} from "../auth/AuthProvider";

const withAuthorization = (allowedRoles) => (Component) => {
  return (props) => {
    const { screens } = useAuth();
    const user = {
      role: screens?.roleName,
    };
    const navigate = useNavigate();

    if (!user && !!screens) {
      return (
        <Box textAlign="center" py={10} px={6}>
          <Heading as="h2" size="xl" mt={6} mb={2}>
            Not Authorized
          </Heading>
          <Text color={"gray.500"}>
            You are not authorized to view this page.
          </Text>
          <Button mt={3} colorScheme="teal" onClick={() => navigate("/login")}>
            Go to Login
          </Button>
        </Box>
      );
    }

    if (!allowedRoles.includes(user.role) && !!screens) {
      return (
        <Box textAlign="center" py={10} px={6}>
          <Heading as="h2" size="xl" mt={6} mb={2}>
            Access Denied
          </Heading>
          <Text color={"gray.500"}>
            You do not have the necessary permissions to view this page.
          </Text>
          <Button mt={3} colorScheme="teal" onClick={() => navigate("/")}>
            Go to Home
          </Button>
        </Box>
      );
    }

    return <Component {...props} />;
  };
};

export default withAuthorization;
