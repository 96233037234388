import {
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import CustomAsyncSelect from "./AsyncSelect";
import { useGet, usePost } from "../../../hooks/api-call";
import useCustomToast from "../../../hooks/use-toast";
import { useYupValidationResolver } from "../../../hooks/use-validation-resolver";
import CustomFormLabel from "../../../atoms/CustomFormLabel";

export const BoxWrapper = ({ children }) => {
  return (
    <Box bg={"#f4f6f9"} p={4} mt={2} borderRadius={"5px"}>
      <Box bg={"white"} p={3} borderRadius={"5px"} pb={3}>
        {children}
      </Box>
    </Box>
  );
};

const validationSchema = Yup.object().shape({
  ownerId: Yup.string().required("Please choose a buyer"),
  poNumber: Yup.string().required("Po number is required"),
  pricePerTon: Yup.number().nullable().positive("Must be positive").notRequired(),
  paymentTo: Yup.string().required("Payment to is required"),
  truckId: Yup.object().required("Truck No. is required"),
  weight: Yup.number().nullable().positive("Must be positive").notRequired(),
});

const TripDrawer = ({ setIsDrawerOpen, fetchTripsData, fetchStageWiseCount }) => {
  const [truckDetail, setTruckDetail] = useState(null);
  const [selectedBuyerId, setSelectedBuyerId] = useState(null);
  const [poNumbers, setPoNumbers] = useState([]);

  const { responseData: ownernames } = useGet(`buyers`);
  const { fetchData: fetchOrders } = useGet();

  const { postData } = usePost();
  const { showSuccessToast } = useCustomToast();

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors, isSubmitting, isDirty, isValid },
  } = useForm({
    resolver: useYupValidationResolver(validationSchema),
    mode: "onBlur",
  });

  const onClose = () => {
    setIsDrawerOpen(false);
    reset({});
  };

  const onSubmit = async (data) => {
    const payload = {
      orderId: data.poNumber,
      truckId: data.truckId.truckId,
      paymentTo: data.paymentTo,
      ravanaNo: data.ravanaNo,
      pricePerTon: data.pricePerTon,
      driverId: data.truckId.driver.driverId,
      tripQuantity: data.weight,
    };
    await postData(payload, "trip");
    showSuccessToast("Success", `Trip created successfully`);
    onClose();
    fetchTripsData(0);
    fetchStageWiseCount(`trips/stageWiseCount`);
  };

  useEffect(() => {
    if (selectedBuyerId) {
      (async () => {
        const resp = await fetchOrders(`buyer/${selectedBuyerId}/orders?buyerId=${selectedBuyerId}`);
        setPoNumbers(resp);
      })();
    } else {
      setPoNumbers([]);
    }
  }, [selectedBuyerId]);

  return (
    <Drawer isOpen={true} placement="right" onClose={onClose} size="xl">
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton mt={4} />
          <Divider />
          <DrawerBody>
            <Box borderBottom="2px solid #628bb7" mb={3}>
              <DrawerHeader>{false ? "Edit Trip" : "New Trip"}</DrawerHeader>
            </Box>
            <Text>Order Information</Text>
            <form onSubmit={handleSubmit(onSubmit)}>
              <BoxWrapper>
                <Flex gap={5}>
                  <FormControl isInvalid={errors.ownerId}>
                    <CustomFormLabel isRequired color={"#191b1c"}>Select Buyer</CustomFormLabel>
                    <Controller
                      name="ownerId"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          placeholder="Choose owner name"
                          onChange={(e) => {
                            field.onChange(e);
                            setSelectedBuyerId(e.target.value);
                            setValue('poNumber', '');
                          }}
                        >
                          {ownernames &&
                            ownernames.length > 0 &&
                            ownernames.map((ownername) => (
                              <option key={ownername.buyerId} value={ownername.buyerId}>
                                {ownername.buyerName}
                              </option>
                            ))}
                        </Select>
                      )}
                    />
                    {errors.ownerId && <Text color="red.500">{errors.ownerId.message}</Text>}
                  </FormControl>
                  <FormControl isInvalid={errors.poNumber}>
                    <CustomFormLabel isRequired color={"#191b1c"}>Po number</CustomFormLabel>
                    <Controller
                      name="poNumber"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          placeholder="Choose po number"
                          disabled={!poNumbers.length}
                        >
                          {poNumbers &&
                            poNumbers.length > 0 &&
                            poNumbers.map((poNumber) => (
                              <option key={poNumber.orderId} value={poNumber.orderId}>
                                {poNumber.poNumber}
                              </option>
                            ))}
                        </Select>
                      )}
                    />
                    {errors.poNumber && <Text color="red.500">{errors.poNumber.message}</Text>}
                  </FormControl>
                  <FormControl isInvalid={errors.pricePerTon}>
                    <FormLabel color={"#191b1c"}>Price per ton</FormLabel>
                    <Controller
                      name="pricePerTon"
                      control={control}
                      render={({ field }) => <Input {...field} type="number" placeholder="Enter price per ton" />}
                    />
                    {errors.pricePerTon && <Text color="red.500">{errors.pricePerTon.message}</Text>}
                  </FormControl>
                </Flex>
              </BoxWrapper>
              <Text mt="4">Trip Information</Text>
              <BoxWrapper>
                <Flex gap={5}>
                  <FormControl isInvalid={errors.paymentTo} isRequired>
                    <FormLabel color={"#191b1c"}>Payment to</FormLabel>
                    <Controller
                      name="paymentTo"
                      control={control}
                      render={({ field }) => (
                        <RadioGroup {...field}>
                          <Stack direction="row">
                            <Radio value="aggregator">Aggregator</Radio>
                            <Radio value="owner">Owner</Radio>
                          </Stack>
                        </RadioGroup>
                      )}
                    />
                    {errors.paymentTo && <Text color="red.500">{errors.paymentTo.message}</Text>}
                  </FormControl>
                  <FormControl isInvalid={errors.truckId} isRequired>
                    <FormLabel color={"#191b1c"}>Truck No.</FormLabel>
                    <Controller
                      name="truckId"
                      control={control}
                      render={({ field }) => (
                        <CustomAsyncSelect {...field} handleChange={(e) => {
                          field.onChange(e);
                          setTruckDetail(e.target.value);
                        }} />
                      )}
                    />
                    {errors.truckId && <Text color="red.500">{errors.truckId.message}</Text>}
                  </FormControl>
                  <FormControl isInvalid={errors.driverName} isRequired>
                    <FormLabel color={"#191b1c"}>Driver Name</FormLabel>
                    <Input
                      value={truckDetail?.driver?.firstName || ""}
                      disabled={true}
                    />
                    {errors.driverName && <Text color="red.500">{errors.driverName.message}</Text>}
                  </FormControl>
                </Flex>
                <Flex gap={5} mt={4}>
                  
                  {/* <FormControl isInvalid={errors.weight}>
                    <FormLabel color={"#191b1c"}>Weight (in tons)</FormLabel>
                    <Controller
                      name="weight"
                      control={control}
                      render={({ field }) => <Input {...field} placeholder="Enter weight" />}
                    />
                    {errors.weight && <Text color="red.500">{errors.weight.message}</Text>}
                  </FormControl> */}
                  {/* <FormControl isInvalid={errors.ravanaNo}>
                    <FormLabel color={"#191b1c"}>Ravana No</FormLabel>
                    <Controller
                      name="ravanaNo"
                      control={control}
                      render={({ field }) => <Input {...field} placeholder="Enter Ravana No" />}
                    />
                    {errors.ravanaNo && <Text color="red.500">{errors.ravanaNo.message}</Text>}
                  </FormControl> */}
                </Flex>
              </BoxWrapper>
              <DrawerFooter>
                <Button
                  type="submit"
                  colorScheme="blue"
                  width={"200px"}
                  mb={4}
                  position={"absolute"}
                  bottom={5}
                  right={5}
                  isDisabled={!isDirty || !isValid}
                >
                  Submit
                </Button>
              </DrawerFooter>
            </form>
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default TripDrawer;
