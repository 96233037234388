import {
    Box,
    Button,
    HStack,
    IconButton,
    Spinner,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
  } from "@chakra-ui/react";
  import React from "react";
  import {
    FiChevronLeft,
    FiChevronRight,
    FiEdit,
    FiTrash2,
    FiTruck,
  } from "react-icons/fi";
  import useConfirmationDialog from "../../hooks/use-confimation-dialog";
  
  const SellerListing = ({
    isLoading,
    sellers,
    handleEditOpen,
    handleRemoveSeller,
    activePage,
    setActivePage,
    totalPages,
    fetchBuyersData,
  }) => {
    const { openConfirmationDialog, Dialog } =
      useConfirmationDialog(handleRemoveSeller);
  
    const handleNextPage = () => {
      if (totalPages > activePage) {
        const page = activePage + 1;
        setActivePage(page);
        fetchBuyersData(page);
      }
    };
  
    const handlePrevPage = () => {
      if (activePage > 1) {
        const page = activePage - 1;
        setActivePage(page);
        fetchBuyersData(page);
      }
    };
  
    return (
      <>
        {Dialog}
        <TableContainer
          w="100%"
          borderRadius={"md"}
          border="1px"
          borderColor="gray.200"
          bg="whiteAlpha.600"
          mt="6"
        >
          {isLoading ? (
            <Box
              h={"60vh"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Spinner size="xl" />
            </Box>
          ) : (
            <Table>
              <Thead bg="blackAlpha.50">
                <Tr>
                  <Th>Seller Name</Th>
                  <Th>Name</Th>
                  <Th>GST No</Th>
                  <Th>PAN No</Th>
                  <Th>TAN No</Th>
                  <Th>Contact No</Th>
                  <Th>Ordered Qty</Th>
                  <Th>Delivered Qty</Th>
                  <Th>Address</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {sellers?.map((seller) => (
                  <Tr key={seller?.buyerId}>
                    <Td>
                      <HStack>
                        <Text color="blackAlpha.600">
                          {seller.mineName}
                        </Text>
                      </HStack>
                    </Td>
                    <Td>{seller?.companyName}</Td>
                    <Td>{seller?.gstNo}</Td>
                    <Td> {`${seller.panNo}`} </Td>
  
                    <Td>{seller?.tanNo} </Td>
                    <Td>{seller?.contactNo} </Td>
                    <Td>{seller?.orderedQuantity} </Td>
                    <Td>{seller?.deliveredQuantity} </Td>
                    <Td>{seller?.address}- {seller?.pincode} </Td>
                    <Td>
                      <Button
                        variant="ghost"
                        onClick={() => handleEditOpen(seller)}
                      >
                        <FiEdit color="blue" />
                      </Button>
                      <Button
                        variant="ghost"
                        onClick={() => openConfirmationDialog(seller.sellerId)}
                      >
                        <FiTrash2 color="red" />
                      </Button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          )}
        </TableContainer>
        <Box style={{ textAlign: "right" }} w="100%" px="5" mt="3">
          <Button disabled={activePage < 2} onClick={handlePrevPage} width={'150px'}>
            Previous
          </Button>
          <span style={{ margin: "0 0.5rem" }}>
            Page {activePage} of {totalPages}
          </span>
          <Button disabled={totalPages === activePage} onClick={handleNextPage} width={'150px'}>
            Next
          </Button>
        </Box>
      </>
    );
  };
  
  export default SellerListing;
  