import { Box } from "@chakra-ui/react";
import React, { useState } from "react";
import SellerSearchHeading from "./SellerSearchHeading";
import SellerFilters from "./SellerFilters";
import SellerListing from "./SellerListing";
import { useDelete, useGet } from "../../hooks/api-call";
import SellerDrawer from "./drawer-forms/SellerDrawer";

const SellerDashboard = () => {
  const [activePage, setActivePage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedSellerForEdit, setSelectedSellerForEdit] = useState(false);

  const { isLoading, responseData, fetchData } = useGet(
    `sellers/search?page=${activePage}&size=${pageSize}&truckHypothecated=${""}`
  );

  const { deleteData } = useDelete();

  const fetchSellersData = (activePage, details) => {
    const sellerId =
      details?.sellerId !== undefined ? `&sellerId=${details.sellerId}` : "";
    const materialId =
      details?.materialId !== undefined
        ? `&materialId=${details.materialId}`
        : "";
    const quantity =
      details?.quantity !== undefined
        ? `&minimumQuantity=${details.quantity}`
        : "";
    const url = `sellers/search?page=${activePage}&size=${pageSize}${sellerId}${materialId}${quantity}`;
    fetchData(url);
  };

  const handleRemoveSeller = (sellerId) => {
    deleteData(null, `sellers/${sellerId}`);
    fetchSellersData(activePage);
  };

  const handleEditOpen = (seller) => {
    setIsDrawerOpen(true);
    setSelectedSellerForEdit(seller);
  };

  return (
    <Box>
      <Box pl="5" pr="5">
        <SellerSearchHeading setIsDrawerOpen={setIsDrawerOpen} />
        <SellerFilters fetchSellersData={fetchSellersData} />
        <SellerListing
          handleRemoveSeller={handleRemoveSeller}
          isLoading={isLoading}
          sellers={responseData?.content}
          activePage={activePage}
          setActivePage={setActivePage}
          pageSize={pageSize}
          totalPages={Math.ceil((responseData?.totalTrucks || 0) / pageSize)}
          fetchSellersData={fetchSellersData}
          handleEditOpen={handleEditOpen}
        />
      </Box>

      {isDrawerOpen && (
        <SellerDrawer
          selectedSellerForEdit={selectedSellerForEdit}
          setIsDrawerOpen={setIsDrawerOpen}
          fetchSellersData={fetchSellersData}
          setSelectedSellerForEdit={setSelectedSellerForEdit}
        />
      )}
    </Box>
  );
};

export default SellerDashboard;
