import { Box } from "@chakra-ui/react";
import React, { useState } from "react";
import { useDelete, useGet } from "../../hooks/api-call";
import TripListing from "./TripListing";
import TripSearchHeading from "./TripSearchHeading";
import TripFilters from "./TripFilters";
import TripDrawer from "./drawer-contents/TripDrawer";
import EditTripDrawer from "./drawer-contents/EditTripDrawer";
import { buildTripURLWithQueryParams } from "../../hooks/helper";
import useCustomToast from "../../hooks/use-toast";
import { useLocation } from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AdminTripDashBoard = () => {
  const [activePage, setActivePage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
  const [editabeTripId, setEditableTripId] = useState(false);
  const [seletctedTabStatus, setSelectedTabStatus] = useState("");
  const { showSuccessToast } = useCustomToast();

  const query = useQuery();
  const selectedOrder = query.get("selectedOrder");

  const { isLoading, responseData, fetchData } = useGet(
    buildTripURLWithQueryParams(`trips/search`, {
      page: activePage,
      size: pageSize,
      orderId: selectedOrder,
    })
  );

  const { responseData: stageWiseCounts, fetchData: fetchStageWiseCount } =
    useGet(`trips/stageWiseCount`);

  const { deleteData } = useDelete();

  const fetchTripsData = async (currentPage, details) => {
    const url = buildTripURLWithQueryParams("trips/search", {
      page: currentPage || activePage,
      size: pageSize,
      ...details,
    });

    fetchData(url);

    const stageUrl = buildTripURLWithQueryParams("trips/stageWiseCount", {
      ...details,
    });
    
    fetchStageWiseCount(stageUrl);
  };

  const handleRemoveTrip = async (tripId) => {
    await deleteData(null, `trips/${tripId}`);
    await fetchTripsData("0");
    showSuccessToast("Success", `Trip deleted successfully`);
  };

  const handleEditOpen = (tripId) => {
    setIsDrawerOpen(false);
    setIsEditDrawerOpen(true);
    setEditableTripId(tripId);
  };

  const handleEditTripClose = () => {
    setIsEditDrawerOpen(false);
    setEditableTripId(false);
    fetchTripsData(0);
  };

  return (
    <Box bg="gray.50">
      <Box pl="5" pr="5">
        <TripSearchHeading setIsDrawerOpen={setIsDrawerOpen} />
        <TripFilters
          fetchTripsData={fetchTripsData}
          seletctedTabStatus={seletctedTabStatus}
          setSelectedTabStatus={setSelectedTabStatus}
        />
        <TripListing
          stageWiseCounts={stageWiseCounts}
          seletctedTabStatus={seletctedTabStatus}
          setSelectedTabStatus={setSelectedTabStatus}
          handleRemoveTrip={handleRemoveTrip}
          isLoading={isLoading}
          trips={responseData?.content}
          activePage={activePage}
          setActivePage={setActivePage}
          pageSize={pageSize}
          totalPages={responseData?.totalPages}
          fetchTripsData={fetchTripsData}
          handleEditOpen={handleEditOpen}
        />
      </Box>
      {isDrawerOpen && (
        <TripDrawer
          setIsDrawerOpen={setIsDrawerOpen}
          fetchTripsData={fetchTripsData}
          fetchStageWiseCount={fetchStageWiseCount}
        />
      )}
      {isEditDrawerOpen && (
        <EditTripDrawer
          editabeTripId={editabeTripId}
          onClose={handleEditTripClose}
        />
      )}
    </Box>
  );
};

export default AdminTripDashBoard;
