import { useState, useEffect } from "react";
import { makeApiRequest } from "./helper";
import { useAuth } from "../auth/AuthProvider";

// Custom hook for fetching data from API
function useGet(endpoint, options = {}, dependencies = []) {
  const { token } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [responseData, setResponseData] = useState(null);

  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const fetchData = async (primaryEndPoint) => {
    setIsLoading(true);
    setError(null);
    try {
      const responseData = await makeApiRequest("GET", (primaryEndPoint || endpoint), null, headers || {});
      setResponseData(responseData);
      setIsLoading(false);
      return responseData;
    } catch (error) {
      setIsLoading(false);
      setError(error);
    }
  };

  useEffect(() => {
    token && fetchData();
  }, [token, ...dependencies]);

  return {
    isLoading,
    error,
    responseData,
    fetchData,
  };
}

// usePost hook
function usePost(endpoint, options = {}, dependencies = []) {
  const { token } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [responseData, setResponseData] = useState(null);

  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
    ...options.headers,
  };

  const postData = async (data = null, primaryEndPoint) => {
    setIsLoading(true);
    setError(null);
    try {
      const responseData = await makeApiRequest(
        "POST",
        primaryEndPoint || endpoint,
        data,
        headers || {}
      );
      setResponseData(responseData);
      setIsLoading(false);
      return responseData;
    } catch (error) {
      setError(error);
      setIsLoading(false);
      throw error;
    }
  };

  return {
    isLoading,
    error,
    responseData,
    postData,
  };
}

// usePut hook
function usePut(endpoint, options = {}, dependencies = []) {
  const { token } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [responseData, setResponseData] = useState(null);

  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
    ...options.headers,
  };

  const putData = async (data = null, primaryEndPoint) => {
    setIsLoading(true);
    setError(null);
    try {
      const responseData = await makeApiRequest(
        "PUT",  // Change the HTTP method to PUT
        primaryEndPoint || endpoint,
        data,
        headers || {}
      );
      setResponseData(responseData);
      setIsLoading(false);
      return responseData;
    } catch (error) {
      console.log(`error new`,error.message);
      setError(error);
      setIsLoading(false);
      throw error;
    }
  };

  return {
    isLoading,
    error,
    responseData,
    putData,  // Return the putData function instead of postData
  };
}

// Modified useDelete hook
function useDelete(endpoint, options = {}, dependencies = []) {
  const { token } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [responseData, setResponseData] = useState(null);

  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const deleteData = async (data = null, primaryEndPoint) => {
    setIsLoading(true);
    setError(null);
    try {
      const responseData = await makeApiRequest(
        "DELETE",
        primaryEndPoint || endpoint,
        data,
        headers || {}
      );
      setResponseData(responseData);
      setIsLoading(false);
      return responseData;
    } catch (error) {
      setError(error);
      setIsLoading(false);
      throw error;
    }
  };

  return {
    isLoading,
    error,
    responseData,
    deleteData,
  };
}

// Core API calling logic
function useApi(
  endpoint,
  method = "GET",
  data = null,
  options = {},
  dependencies = []
) {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [responseData, setResponseData] = useState(null);
  const { token } = useAuth();

  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const responseData = await makeApiRequest(
          method,
          endpoint,
          data,
          headers || {}
        );
        setResponseData(responseData);
        setError(null);
      } catch (error) {
        setError(error);
        setResponseData(null);
      }
      setIsLoading(false);
    };

    token && fetchData();
    // Add dependencies to trigger API call when they change
  }, [...dependencies, token]); // eslint-disable-line react-hooks/exhaustive-deps

  return { isLoading, error, responseData };
}

export { useGet, usePost, usePut, useDelete };
