import React from "react";
import {
  ChakraProvider,
  Box,
  Grid,
  GridItem,
  Stat,
  StatLabel,
  StatNumber,
  Heading,
  Stack,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import { Bar, Line, Pie, Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const SellerDashboard = () => {
  const tripsData = {
    labels: ["January", "February", "March", "April", "May"],
    datasets: [
      {
        label: "Number of Trips",
        data: [65, 59, 80, 81, 56],
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,192,192,1)",
        borderWidth: 1,
      },
    ],
  };

  const ordersData = {
    labels: ["Electronics", "Groceries", "Clothing", "Books", "Others"],
    datasets: [
      {
        label: "Number of Orders",
        data: [300, 450, 100, 200, 150],
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
        ],
      },
    ],
  };

  const sellersData = {
    labels: ["Month 1", "Month 2", "Month 3", "Month 4", "Month 5"],
    datasets: [
      {
        label: "Active Sellers",
        data: [50, 60, 70, 80, 90],
        backgroundColor: "rgba(153, 102, 255, 0.6)",
        borderColor: "rgba(153, 102, 255, 1)",
        borderWidth: 1,
      },
    ],
  };

  const trucksData = {
    labels: ["Truck A", "Truck B", "Truck C", "Truck D", "Truck E"],
    datasets: [
      {
        label: "Truck Utilization",
        data: [85, 65, 70, 75, 90],
        backgroundColor: "rgba(255,99,132,0.6)",
        borderColor: "rgba(255,99,132,1)",
        borderWidth: 1,
      },
    ],
  };

  const buyersData = {
    labels: ["Buyer 1", "Buyer 2", "Buyer 3", "Buyer 4", "Buyer 5"],
    datasets: [
      {
        label: "Purchase Frequency",
        data: [20, 25, 30, 35, 40],
        backgroundColor: "rgba(54, 162, 235, 0.6)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
      },
    ],
  };

  const ordersSummary = [
    { id: 1, item: "Electronics", quantity: 300, price: "$15000" },
    { id: 2, item: "Groceries", quantity: 450, price: "$9000" },
    { id: 3, item: "Clothing", quantity: 100, price: "$5000" },
    { id: 4, item: "Books", quantity: 200, price: "$3000" },
    { id: 5, item: "Others", quantity: 150, price: "$4500" },
  ];

  return (
    <ChakraProvider>
      <Box p={5}>
        <Heading mb={5}>Seller Dashboard</Heading>
        <Grid templateColumns="repeat(3, 1fr)" gap={6}>
          <GridItem w="100%" h="10">
            <Stat>
              <StatLabel>Total Trips</StatLabel>
              <StatNumber>500</StatNumber>
            </Stat>
          </GridItem>
          <GridItem w="100%" h="10">
            <Stat>
              <StatLabel>Total Orders</StatLabel>
              <StatNumber>1200</StatNumber>
            </Stat>
          </GridItem>
          <GridItem w="100%" h="10">
            <Stat>
              <StatLabel>Total Sellers</StatLabel>
              <StatNumber>150</StatNumber>
            </Stat>
          </GridItem>
          <GridItem w="100%" h="10">
            <Stat>
              <StatLabel>Total Trucks</StatLabel>
              <StatNumber>75</StatNumber>
            </Stat>
          </GridItem>
          <GridItem w="100%" h="10">
            <Stat>
              <StatLabel>Total Buyers</StatLabel>
              <StatNumber>1000</StatNumber>
            </Stat>
          </GridItem>
        </Grid>

        <Stack spacing={8} mt={10}>
          <Flex wrap="wrap" justify="space-between">
            <Box p={5} shadow="md" borderWidth="1px" flex="1" minW="300px" m={2}>
              <Heading fontSize="xl">Trips Over Time</Heading>
              <Bar data={tripsData} />
            </Box>
            <Box p={5} shadow="md" borderWidth="1px" flex="1" minW="300px" m={2}>
              <Heading fontSize="xl">Orders by Category</Heading>
              <Pie data={ordersData} />
            </Box>
            <Box p={5} shadow="md" borderWidth="1px" flex="1" minW="300px" m={2}>
              <Heading fontSize="xl">Active Sellers</Heading>
              <Line data={sellersData} />
            </Box>
          </Flex>

          <Flex wrap="wrap" justify="space-between">
            <Box p={5} shadow="md" borderWidth="1px" flex="1" minW="300px" m={2}>
              <Heading fontSize="xl">Truck Utilization</Heading>
              <Doughnut data={trucksData} />
            </Box>
            <Box p={5} shadow="md" borderWidth="1px" flex="1" minW="300px" m={2}>
              <Heading fontSize="xl">Buyer Purchase Frequency</Heading>
              <Line data={buyersData} />
            </Box>
          </Flex>

          <Box p={5} shadow="md" borderWidth="1px">
            <Heading fontSize="xl">Orders Summary</Heading>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>ID</Th>
                  <Th>Item</Th>
                  <Th>Quantity</Th>
                  <Th>Price</Th>
                </Tr>
              </Thead>
              <Tbody>
                {ordersSummary.map((order) => (
                  <Tr key={order.id}>
                    <Td>{order.id}</Td>
                    <Td>{order.item}</Td>
                    <Td>{order.quantity}</Td>
                    <Td>{order.price}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </Stack>
      </Box>
    </ChakraProvider>
  );
};

export default SellerDashboard;
