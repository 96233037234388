import { useToast } from "@chakra-ui/react";

const useCustomToast = () => {
  const toast = useToast();
  const showErrorToast = (
    title,
    description,
    duration = 5000,
    isClosable = true,
    position = "top-right",
  ) => {
    console.log(`title,
    description,`,title,
    description,);
    showToast({
      title,
      description,
      status: "error",
      duration,
      isClosable,
      position,
    });
  };

  const showSuccessToast = (
    title,
    description,
    duration = 5000,
    isClosable = true,
    position = "top",
  ) => {
    showToast({
      title,
      description,
      status: "success",
      duration,
      isClosable,
      position,
    });
  };

  const showInfoToast = (
    title,
    description,
    duration = 5000,
    isClosable = true,
    position = "top",
  ) => {
    showToast({
      title,
      description,
      status: "info",
      duration,
      isClosable,
      position,
    });
  };

  const showToast = ({
    title,
    description,
    status,
    duration = 5000,
    isClosable = true,
    position = "top",
  }) => {
    // Here you can use your toast library or implement your own toast component
    // For this example, let's assume you have a toast library and you're calling toast function
    toast({
      title,
      description,
      status,
      duration,
      isClosable,
      position,
    });
  };

  return { showErrorToast, showSuccessToast, showInfoToast };
};

export default useCustomToast;
