import { Button, Flex, Input } from "@chakra-ui/react";
import React from "react";

const OrderSearchHeading = ({ setIsDrawerOpen }) => {
  return (
    <Flex justifyContent={"space-between"}>
      <Input disabled type="search" w={"40%"} placeholder="Search invoices" />
      <Button
        onClick={() => setIsDrawerOpen(true)}
        variant={"solid"}
        bg="blue.600"
        color="white"
        w="200px"
      >
        Create new Order
      </Button>
    </Flex>
  );
};

export default OrderSearchHeading;
