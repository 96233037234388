import React, { useState } from "react";
import { useGet } from "../../hooks/api-call";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Select,
  Text,
} from "@chakra-ui/react";
import NumberInput from "../../atoms/NumberInput";

const SellerFilters = ({ fetchSellersData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [filters, setFilters] = useState();

  const { responseData: sellers } = useGet(`sellernames`);
  const { responseData: minerals } = useGet(`minerals`);

  const handleChange = ({ target: { name, value } }) => {
    setFilters((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleSearch = (data) => {
    fetchSellersData(1, data || filters);
  };

  const handleClearFilters = () => {
    const data = { ownerId: "", driverId: "", truckHypothecated: "" };
    setFilters(data);
    setTimeout(() => handleSearch(data), 100);
  };

  const handleOpenFilter = () => {
    setIsOpen((prev) => {
      const element = document.getElementById("truck-filter-section");
      if (prev) {
        element.style.height = "0px";
        setFilters({
          ownerId: "",
          driverId: "",
          truckHypothecated: "",
        });
      } else {
        setTimeout(() => {
          element.style.height = element.scrollHeight + "px";
        });
      }
      return !prev;
    });
  };

  return (
    <Box
      mt={5}
      bg={"#fff"}
      p={4}
      boxSizing="border-box"
      borderRadius={"5px"}
      boxShadow={"base"}
    >
      <Flex
        alignItems={"center"}
        style={{ userSelect: "none" }}
        mb={isOpen ? 2 : 0}
        cursor={"pointer"}
        onClick={handleOpenFilter}
      >
        {isOpen ? <BsChevronUp /> : <BsChevronDown />}
        <Text
          ml={2}
          fontWeight={"bold"}
        >
          Filter
        </Text>
      </Flex>
      <Box
        id="truck-filter-section"
        h={0}
        transition={"height 0.4s ease-out"}
        overflow={"hidden"}
        bg={"#eef2f5"}
        p={isOpen && 2}
        borderRadius={5}
      >
        <Flex gap={2} align="end">
          <FormControl>
            <FormLabel color="gray.600">Sellers</FormLabel>
            <Select
              w="300px"
              name="sellerId"
              onChange={handleChange}
              value={filters?.sellerId}
              placeholder={" Select Sellers"}
            >
              {sellers &&
                sellers.length > 0 &&
                sellers.map((seller, index) => {
                  return (
                    <option value={seller.sellerId} key={index}>
                      {seller?.mineName}
                    </option>
                  );
                })}
            </Select>
          </FormControl>

          {/* Form Field 2 */}
          <FormControl>
            <FormLabel color="gray.600">Minerals</FormLabel>
            <Select
              name="materialId"
              onChange={handleChange}
              value={filters?.materialId}
              placeholder={"Choose minerals"}
              disabled={true}
            >
              {minerals &&
                minerals.length > 0 &&
                minerals.map((mineral, index) => {
                  return (
                    <option key={mineral.materialId} value={mineral.materialId}>
                      {mineral?.materialName}{" "}
                    </option>
                  );
                })}
            </Select>
          </FormControl>

          {/* Form Field 3 */}
          <FormControl>
            <FormLabel color="gray.600">Trip status</FormLabel>
            <Select
              name="tripStatus"
              onChange={handleChange}
              alue={filters?.truckHypothecated}
              placeholder={"Choose option"}
              disabled={true}
            >
              <option value="allocated">Allocated</option>
              <option value="finalised">Finalised</option>
              <option value="arrived_at_mwl">Arrived at MWL</option>
              <option value="stock_allocated">Stock Allocated</option>
              <option value="left_mwl">Left MWl</option>
              <option value="truck_in_transit">Truck In transit</option>
            </Select>
          </FormControl>

          {/* Form Field 3 */}
          <FormControl>
            <FormLabel color="gray.600">Quantity</FormLabel>
            <NumberInput
              disabled={true}
              name="quantity"
              onChange={handleChange}
              placeholder="Enter quantity"
            />
          </FormControl>
        </Flex>
        <Flex justifyContent={"flex-end"} mt={3}>
          <Button
            variant={"solid"}
            bg={
              Object.values({filters}).filter(Boolean).length === 0
                ? " "
                : "gray.200"
            }
            mr={3}
            w="100px"
            onClick={handleClearFilters}
            color={
              Object.values({filters}).filter(Boolean).length === 0
                ? "gray.300"
                : ""
            }
          >
            Clear all
          </Button>
          <Button
            variant={"solid"}
            bg="blue.600"
            color="white"
            w="180px"
            onClick={() => handleSearch()}
            _hover={{
              backgroundColor: "#054180",
            }}
          >
            Search
          </Button>
        </Flex>
      </Box>
    </Box>
  );
};

export default SellerFilters;
