import axios from "axios";

const apiClient = axios.create({
  baseURL: "https://mwmlogistic.in", // server base URL here stage
  timeout: 10000, // Adjust timeout as needed
});

// Helper function for making API requests
async function makeApiRequest(method, endpoint, data = null, headers = {}) {
  try {
    const response = await apiClient.request({
      method,
      url: endpoint,
      data,
      headers,
    });

    return response.data;
  } catch (error) {
    // if (error?.code === "ERR_BAD_REQUEST") {
    //   localStorage.removeItem("token");
    //   window.location.href = "/login";
    //   return;
    // }
    // Handle different types of errors
    if (error.response) {
      // Server responded with a status code outside of 2xx range
      console.error("Server responded with an error:", error.response.data);
      throw error;
    } else if (error.request) {
      // Request made but no response received
      console.error("No response received from server");
      throw new Error("No response received from server");
    } else {
      // Something else went wrong
      console.error("Error setting up the request:", error.message);
      throw new Error("Error setting up the request");
    }
  }
}

function convertMillisecondsToDate(ms) {
  // Create a new Date object using milliseconds
  var date = new Date(ms);

  // Extract day, month, and year
  var day = date.getDate();
  var month = date.getMonth() + 1; // Note: getMonth() returns zero-based month
  var year = date.getFullYear();

  // Format day and month to have leading zeros if needed
  if (day < 10) {
    day = "0" + day;
  }
  if (month < 10) {
    month = "0" + month;
  }

  // Concatenate day, month, and year in the "dd/mm/yyyy" format
  var formattedDate = year + "-" + month + "-" + day;

  return formattedDate;
}

function buildURLWithQueryParams(baseURL, details) {
  let url = baseURL + "?";

  // Check if page exists in details
  if (details.page) {
    url += `page=${details.page}&`;
  }

  // Check if size exists in details
  if (details.size) {
    url += `size=${details.size}&`;
  }

  // Check if materialId exists in details
  if (details.materialId) {
    url += `materialId=${details.materialId}&`;
  }

  // Check if buyerId exists in details
  if (details.buyerId) {
    url += `buyerId=${details.buyerId}&`;
  }

  // Check if poNumber exists in details
  if (details.poNumber) {
    url += `poNumber=${details.poNumber}&`;
  }

  // Remove trailing '&' if it exists
  if (url.endsWith("&")) {
    url = url.slice(0, -1);
  }

  return url;
}

function buildTripURLWithQueryParams(baseURL, details) {
  let url = baseURL + "?";

  // Check if page exists in details
  if (details.page) {
    url += `page=${details.page}&`;
  }

  // Check if size exists in details
  if (details.size) {
    url += `size=${details.size}&`;
  }

  // Check if materialId exists in details
  if (details.materialId) {
    url += `materialId=${details.materialId}&`;
  }

  // Check if buyerId exists in details
  if (details.buyerId) {
    url += `buyerId=${details.buyerId}&`;
  }

  // Check if poNumber exists in details
  if (details.tripStatus) {
    url += `tripStageId=${details.tripStatus}&`;
  }

   // Check if orderId exists in details
   if (details.orderId) {
    url += `orderId=${details.orderId}&`;
  }

  if (details.truckId) {
    url += `truckId=${details.truckId}&`;
  }

  // Remove trailing '&' if it exists
  if (url.endsWith("&")) {
    url = url.slice(0, -1);
  }

  return url;
}

function mergeTripDetails(stages, details) {
  return stages.map(stage => {
      // Find the matching detail for the current stage
      const detail = details.find(d => d.stage.tripStageId === stage.tripStageId);
      
      // If detail found, merge it with the stage
      if (detail) {
          return {
              ...stage,
              tripTrackerId: detail.tripTrackerId,
              weightAtThisStage: detail.weightAtThisStage,
              ravanaNumber: detail.ravanaNumber,
              updatedAt: detail.updatedAt,
              finalPayment: detail.finalPayment
          };
      }
      
      // If no matching detail, return the stage as is
      return stage;
  });
}

// Export the helper function
export { makeApiRequest, convertMillisecondsToDate, buildURLWithQueryParams, buildTripURLWithQueryParams, mergeTripDetails };
