import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useGet } from "../../../hooks/api-call";
import CustomFormLabel from "../../../atoms/CustomFormLabel";
import { Controller } from "react-hook-form";

const OrderBuyerSection = ({ onChange, initialValue, control, errors }) => {
  const [selectedBuyer, setSelectedBuyer] = useState(initialValue || {});

  const { responseData: buyers } = useGet(`buyers`);

  const handleChange = (buyer) => {
    const modifiedValue = {
      target: {
        name: "buyerId",
        value: buyer.buyerId,
      },
    };
    setSelectedBuyer(buyer);
    onChange(modifiedValue);
  };

  return (
    <Box>
      <Flex gap={2} align="end">
        <FormControl isInvalid={errors.buyerId}>
          <CustomFormLabel isRequired color={"#191b1c"}>
            Buyer
          </CustomFormLabel>
          <Controller
            name="buyerId"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                placeholder="Select buyer"
                onChange={(e) => {
                  const buyer = JSON.parse(e.target.value);
                  field.onChange(buyer.buyerId); // set the buyerId in the form state
                  handleChange(buyer); // handle other changes if necessary
                }}
                value={field.value}
              >
                {buyers &&
                  buyers.length > 0 &&
                  buyers.map((buyer, index) => (
                    <option value={JSON.stringify(buyer)} key={index}>
                      {buyer?.buyerName}
                    </option>
                  ))}
              </Select>
            )}
          />
          <FormErrorMessage>
            {errors.buyerId && errors.buyerId.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isRequired>
          <FormLabel color={"#191b1c"}>Buyer Name</FormLabel>
          <Input placeholder="" value={selectedBuyer?.buyerName} disabled />
        </FormControl>
        <FormControl>
          <FormLabel color={"#191b1c"}>GST No</FormLabel>
          <Input type="text" value={selectedBuyer?.gstNo} disabled />
        </FormControl>
      </Flex>
      <Flex gap={5} mt={4}>
        <FormControl>
          <FormLabel color={"#191b1c"}>PAN No</FormLabel>
          <Input type="text" value={selectedBuyer?.panNo} disabled />
        </FormControl>
        <FormControl>
          <FormLabel color={"#191b1c"}>Contact No.</FormLabel>
          <Input type="number" value={selectedBuyer?.contactNo} disabled />
        </FormControl>
        <FormControl>
          <FormLabel color={"#191b1c"}>Email ID</FormLabel>
          <Input type="text" value={selectedBuyer?.emailId} disabled />
        </FormControl>
      </Flex>
      <FormControl mt={4}>
        <FormLabel color={"#191b1c"}>Delivery Address</FormLabel>
        <Input
          type="text"
          value={
            selectedBuyer.address
              ? `${(selectedBuyer?.address || "") + ", "} ${
                  (selectedBuyer?.locality || "") + ", "
                } ${(selectedBuyer?.district || "") + ", "} ${
                  (selectedBuyer?.state || "") + ", "
                } ${(selectedBuyer?.pincode || "") + ", "}`
              : ""
          }
          disabled
        />
      </FormControl>
    </Box>
  );
};

export default OrderBuyerSection;
