import React from "react";
import {
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Text,
} from "@chakra-ui/react";
import { BoxWrapper } from "../../../admin-section/trips/drawer-contents/TripDrawer";
import { useGet, usePost, usePut } from "../../../hooks/api-call";
import useCustomToast from "../../../hooks/use-toast";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { useYupValidationResolver } from "../../../hooks/use-validation-resolver";
import NewAddressDetails from "../../trucks/drawer-forms/NewAddressDetails";
import CustomFormLabel from "../../../atoms/CustomFormLabel";
import { createEmailSchema, createGstSchema, createPanSchema, pincodeSchema } from "../../../hooks/validationSchemas";

const validationSchema = Yup.object().shape({
  category: Yup.string(),
  mineName: Yup.string(),
  companyName: Yup.string().required("Company name is required"),
  panNo: createPanSchema(),
  contactNo: Yup.string()
  .nullable()
  .test('is-valid-contact', 'Invalid contact number', (value) => {
    if (!value) return true; // If the field is empty or null, it's valid
    return /^\d{10}$/.test(value); // If the field has a value, validate it
  }),
  emailId: createEmailSchema(),
  gstNo: createGstSchema(),
  // address: Yup.string().required("Address is required"),
  pincode: pincodeSchema,
});

const SellerDrawer = ({
  setIsDrawerOpen,
  selectedSellerForEdit,
  setSelectedSellerForEdit,
  fetchSellersData,
}) => {
  const { postData } = usePost();
  const { fetchData } = useGet();
  const { putData } = usePut();
  const { showSuccessToast } = useCustomToast();

  const { register, handleSubmit, reset, setValue, control, formState: { errors, isDirty, isValid } } = useForm({
    resolver: useYupValidationResolver(validationSchema),
    defaultValues: selectedSellerForEdit,
    mode: "onBlur",
  });

  const onClose = () => {
    setIsDrawerOpen(false);
    reset({});
    setSelectedSellerForEdit(false)
  };

  const onSubmit = async (formData) => {
    try {
      if (selectedSellerForEdit) {
        await putData(formData, `sellers/${selectedSellerForEdit?.sellerId}`);
        showSuccessToast("Success", "Seller updated successfully");
        fetchSellersData(0, {});
        onClose();
      } else {
        await postData(formData, "seller");
        showSuccessToast("Success", "Seller added successfully");
        fetchSellersData(0, {});
        onClose();
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle error display or logging as needed
    }
  };

  return (
    <Drawer
      isOpen={selectedSellerForEdit ? Object.keys(selectedSellerForEdit).length > 0 : true}
      placement="right"
      onClose={onClose}
      size="xl"
    >
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton mt={4} />
          <Divider />
          <form onSubmit={handleSubmit(onSubmit)}>
            <DrawerBody maxH={"89vh"}>
              <Box borderBottom="2px solid #628bb7" mb={3}>
                <DrawerHeader>
                  {selectedSellerForEdit ? "Edit Seller" : "New Seller"}
                </DrawerHeader>
              </Box>
              <Text>Seller Information</Text>
              <BoxWrapper>
                <Flex gap={5}>
                  <FormControl isInvalid={errors.category}>
                    <CustomFormLabel color={"#191b1c"}>Category</CustomFormLabel>
                    <Select
                      {...register("category")}
                      placeholder="Select category"
                    >
                      <option value="manufacture">Manufacture</option>
                      <option value="dealer">Dealer</option>
                      <option value="other">Other</option>
                    </Select>
                    {errors.category && (
                      <Text color="red.500">{errors.category.message}</Text>
                    )}
                  </FormControl>
                  <FormControl isInvalid={errors.mineName}>
                    <CustomFormLabel color={"#191b1c"}>Name</CustomFormLabel>
                    <Input
                      {...register("mineName")}
                      placeholder="Enter name"
                    />
                    {errors.mineName && (
                      <Text color="red.500">{errors.mineName.message}</Text>
                    )}
                  </FormControl>
                  <FormControl isInvalid={errors.companyName}>
                    <CustomFormLabel isRequired color={"#191b1c"}>Company Name</CustomFormLabel>
                    <Input
                      {...register("companyName")}
                      placeholder="Enter Company name"
                    />
                    {errors.companyName && (
                      <Text color="red.500">{errors.companyName.message}</Text>
                    )}
                  </FormControl>
                </Flex>
                <Flex gap={5} mt={4}>
                  <FormControl isInvalid={errors.panNo}>
                    <FormLabel color={"#191b1c"}>PAN No</FormLabel>
                    <Input
                      {...register("panNo")}
                      placeholder="Enter PAN number"
                    />
                    {errors.panNo && (
                      <Text color="red.500">{errors.panNo.message}</Text>
                    )}
                  </FormControl>
                  <FormControl isInvalid={errors.contactNo}>
                    <CustomFormLabel color={"#191b1c"}>Contact No.</CustomFormLabel>
                    <Input
                      {...register("contactNo")}
                      type="number"
                      placeholder="Enter contact number"
                    />
                    {errors.contactNo && (
                      <Text color="red.500">{errors.contactNo.message}</Text>
                    )}
                  </FormControl>
                  <FormControl isInvalid={errors.emailId}>
                    <FormLabel color={"#191b1c"}>Email ID</FormLabel>
                    <Input
                      {...register("emailId")}
                      placeholder="Enter email"
                    />
                    {errors.emailId && (
                      <Text color="red.500">{errors.emailId.message}</Text>
                    )}
                  </FormControl>
                </Flex>
                <Flex mt={4} gap={4}>
                  <FormControl isInvalid={errors.gstNo}>
                    <FormLabel color={"#191b1c"}>GST No</FormLabel>
                    <Input
                      {...register("gstNo")}
                      placeholder="Enter GST number"
                    />
                    {errors.gstNo && (
                      <Text color="red.500">{errors.gstNo.message}</Text>
                    )}
                  </FormControl>
                  <FormControl>
                    <FormLabel color={"#191b1c"}>Tan No</FormLabel>
                    <Input
                      {...register("tanNo")}
                      placeholder="Enter TAN number"
                    />
                    {errors.tanNo && (
                      <Text color="red.500">{errors.tanNo.message}</Text>
                    )}
                  </FormControl>
                </Flex>
              </BoxWrapper>
              <BoxWrapper>
                <NewAddressDetails
                  control={control}
                  errors={errors}
                />
              </BoxWrapper>
            </DrawerBody>
            <Button
              colorScheme="blue"
              width={"200px"}
              position={"absolute"}
              bottom={5}
              right={5}
              type="submit"
              isDisabled={!isDirty || !isValid}
            >
              {selectedSellerForEdit ? "Update Seller" : "Add Seller"}
            </Button>
          </form>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default SellerDrawer;
