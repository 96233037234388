import React from 'react';
import {
  Box,
  Flex,
  FormControl,
  Input,
  Text,
  FormErrorMessage,
} from '@chakra-ui/react';
import { Controller } from 'react-hook-form';
import CustomFormLabel from '../../../atoms/CustomFormLabel';

const NewAddressDetails = ({ control, errors }) => {
  return (
    <Box>
      <Text fontWeight={'bold'} color={'#9d9b9b'} mb={3}>
        Address Details
      </Text>
      <Flex justify="space-between" w="100%" gap={3} mb={3}>
        <FormControl isInvalid={errors.address}>
          <CustomFormLabel color={'#191b1c'}>
            Address
          </CustomFormLabel>
          <Controller
            name="address"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
          <FormErrorMessage>
            {errors.address && errors.address.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl w="60%" isInvalid={errors.landmark}>
          <CustomFormLabel color={'#191b1c'}>Landmark</CustomFormLabel>
          <Controller
            name="landmark"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
          <FormErrorMessage>
            {errors.landmark && errors.landmark.message}
          </FormErrorMessage>
        </FormControl>
      </Flex>
      <Flex justify="space-between" w="100%" gap={3} mb={3}>
        <FormControl isInvalid={errors.locality}>
          <CustomFormLabel color={'#191b1c'}>Village/town</CustomFormLabel>
          <Controller
            name="locality"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
          <FormErrorMessage>
            {errors.locality && errors.locality.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={errors.tehsil}>
          <CustomFormLabel color={'#191b1c'}>Tehsil</CustomFormLabel>
          <Controller
            name="tehsil"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
          <FormErrorMessage>
            {errors.tehsil && errors.tehsil.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={errors.district}>
          <CustomFormLabel color={'#191b1c'}>District</CustomFormLabel>
          <Controller
            name="district"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
          <FormErrorMessage>
            {errors.district && errors.district.message}
          </FormErrorMessage>
        </FormControl>
      </Flex>
      <Flex justify="space-between" w="100%" gap={3} mb={3}>
        <FormControl w="50%" isInvalid={errors.state}>
          <CustomFormLabel color={'#191b1c'}>State</CustomFormLabel>
          <Controller
            name="state"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
          <FormErrorMessage>
            {errors.state && errors.state.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={errors.pincode}>
          <CustomFormLabel color={'#191b1c'}>
            Pincode
          </CustomFormLabel>
          <Controller
            name="pincode"
            control={control}
            render={({ field }) => <Input {...field} type="number" />}
          />
          <FormErrorMessage>
            {errors.pincode && errors.pincode.message}
          </FormErrorMessage>
        </FormControl>
      </Flex>
    </Box>
  );
};

export default NewAddressDetails;
