import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  Select,
  Text,
} from "@chakra-ui/react";
import { useForm, Controller } from "react-hook-form";
import { BoxWrapper } from "../../trips/drawer-contents/TripDrawer";
import { usePost, usePut } from "../../../hooks/api-call";
import useCustomToast from "../../../hooks/use-toast";
import OrderBuyerSection from "./OrderBuyerSection";
import OrderSellerSection from "./OrderSellerSection";
import { convertMillisecondsToDate } from "../../../hooks/helper";
import * as Yup from "yup";
import CustomFormLabel from "../../../atoms/CustomFormLabel";
import { useYupValidationResolver } from "../../../hooks/use-validation-resolver";

const validationSchema = Yup.object().shape({
  poNumber: Yup.string().required("PO Number is required"),
  poDate: Yup.date()
    .required("PO Date is required")
    .test(
      "poDate-validation",
      "PO Date must be earlier than Expected Delivery Date",
      function (value) {
        const { expectedDeliveryDate } = this.parent;
        if (expectedDeliveryDate && value) {
          return new Date(value) < new Date(expectedDeliveryDate);
        }
        return true;
      }
    ),
  expectedDeliveryDate: Yup.date()
    .nullable()
    .min(new Date(), "Expected Delivery Date must be in the future")
    .test(
      "expectedDeliveryDate-validation",
      "Expected Delivery Date must be later than PO Date",
      function (value) {
        const { poDate } = this.parent;
        if (poDate && value) {
          return new Date(value) > new Date(poDate);
        }
        return true;
      }
    ),
  materialId: Yup.string().required("Material is required"),
  buyerId: Yup.string().required("BuyerId is required"),
  sellerId: Yup.string().required("Seller is required"),
});

const OrderDrawer = ({
  setIsDrawerOpen,
  fetchOrdersData,
  selectedOrderForEdit,
  setSelectedOrderForEdit,
  minerals
}) => {
  const { showSuccessToast } = useCustomToast();
  const { postData } = usePost();
  const { putData } = usePut();
  const [selectedMineral, setSelectedMineral] = useState({});

  const resolver = useYupValidationResolver(validationSchema);
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isSubmitting, isDirty, isValid },
  } = useForm({
    resolver,
    mode: "onBlur",
    defaultValues: selectedOrderForEdit,
  });

  const onClose = () => {
    setIsDrawerOpen(false);
    setSelectedOrderForEdit(null);
    reset();
  };

  const handleSubmitForm = async (data) => {
    if (selectedOrderForEdit) {
      await putData(
        data,
        `order/${selectedOrderForEdit?.orderId}?buyerId=${data.buyerId}&sellerId=${data.sellerId}&materialId=${data.materialId}`
      );
      showSuccessToast("Success", "Order updated successfully");
    } else {
      await postData(
        data,
        `order?buyerId=${data.buyerId}&sellerId=${data.sellerId}&materialId=${data.materialId}`
      );
      showSuccessToast("Success", "Order created successfully");
    }
    fetchOrdersData("0", {});
    onClose();
  };

  const handleSelectedMineral = (e) => {
    const mineralId = e.target.value;
    setValue("materialId", mineralId);
    const resp = minerals.find((f) => +f.materialId === +mineralId);
    if (resp) {
      setSelectedMineral(resp);
    }
  };
  
  useEffect(() => {
    if (selectedOrderForEdit) {
      const {
        poNumber,
        totalQuantity,
        price,
        orderStatus,
        material,
        buyer,
        seller,
        poDate,
        expectedDeliveryDate,
      } = selectedOrderForEdit;
      setValue("poNumber", poNumber);
      setValue("poDate", convertMillisecondsToDate(poDate));
      setValue(
        "expectedDeliveryDate",
        convertMillisecondsToDate(expectedDeliveryDate)
      );
      setValue("materialId", material.materialId);
      setValue("buyerId", buyer.buyerId);
      setValue("sellerId", seller.sellerId);
      setValue("totalQuantity", totalQuantity);
      setValue("price", price);
      setValue("orderStatus", orderStatus);
      const data = minerals.find((m) => m.materialId === material.materialId);
      setSelectedMineral(data);
    }
  }, [selectedOrderForEdit]);

  return (
    <Drawer isOpen={true} placement="right" onClose={onClose} size="xl">
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton mt={4} />
          <Divider />
          <form onSubmit={handleSubmit(handleSubmitForm)}>
            <DrawerBody maxH={"89vh"}>
              <Box borderBottom="2px solid #628bb7" mb={3}>
                <DrawerHeader>
                  {selectedOrderForEdit ? "Edit Order" : "New Order"}
                </DrawerHeader>
              </Box>
              <Text>Order Information</Text>
              <BoxWrapper>
                <Flex gap={5}>
                  <FormControl isInvalid={errors.poNumber}>
                    <CustomFormLabel isRequired color={"#191b1c"}>
                      PO Number
                    </CustomFormLabel>
                    <Controller
                      name="poNumber"
                      control={control}
                      render={({ field }) => (
                        <Input {...field} type="number" colorScheme="gray" />
                      )}
                    />
                    <FormErrorMessage>
                      {errors.poNumber && errors.poNumber.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.poDate}>
                    <CustomFormLabel isRequired color={"#191b1c"}>
                      PO Date
                    </CustomFormLabel>
                    <Controller
                      name="poDate"
                      control={control}
                      render={({ field }) => (
                        <Input {...field} type="date" colorScheme="gray" />
                      )}
                    />
                    <FormErrorMessage>
                      {errors.poDate && errors.poDate.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.expectedDeliveryDate}>
                    <CustomFormLabel color={"#191b1c"}>
                      Expected Delivery Date
                    </CustomFormLabel>
                    <Controller
                      name="expectedDeliveryDate"
                      control={control}
                      render={({ field }) => <Input {...field} type="date" />}
                    />
                    <FormErrorMessage>
                      {errors.expectedDeliveryDate &&
                        errors.expectedDeliveryDate.message}
                    </FormErrorMessage>
                  </FormControl>
                </Flex>
                <Flex gap={5} mt={4}>
                  <FormControl isInvalid={errors.materialId}>
                    <CustomFormLabel isRequired color={"#191b1c"}>
                      Minerals
                    </CustomFormLabel>
                    <Controller
                      name="materialId"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          onChange={handleSelectedMineral}
                          placeholder="Choose minerals"
                        >
                          {!!minerals &&
                            minerals.length > 0 &&
                            minerals.map((mineral) => (
                              <option
                                key={mineral.materialId}
                                value={mineral.materialId}
                              >
                                {mineral.materialName}
                              </option>
                            ))}
                        </Select>
                      )}
                    />
                    <FormErrorMessage>
                      {errors.materialId && errors.materialId.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl>
                    <CustomFormLabel color={"#191b1c"}>
                      Category
                    </CustomFormLabel>
                    <Input value={selectedMineral?.categoryName} disabled />
                  </FormControl>
                  <FormControl isInvalid={errors.totalQuantity}>
                    <CustomFormLabel color={"#191b1c"}>
                      Total Quantity
                    </CustomFormLabel>
                    <Controller
                      name="totalQuantity"
                      control={control}
                      render={({ field }) => <Input {...field} type="number" />}
                    />
                    <FormErrorMessage>
                      {errors.totalQuantity && errors.totalQuantity.message}
                    </FormErrorMessage>
                  </FormControl>
                </Flex>
                <Flex mt={4} gap={4}>
                  <FormControl isInvalid={errors.price}>
                    <CustomFormLabel color={"#191b1c"}>Price</CustomFormLabel>
                    <Controller
                      name="price"
                      control={control}
                      render={({ field }) => <Input {...field} type="number" />}
                    />
                    <FormErrorMessage>
                      {errors.price && errors.price.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.orderStatus}>
                    <CustomFormLabel color={"#191b1c"}>
                      Order Status
                    </CustomFormLabel>
                    <Controller
                      name="orderStatus"
                      control={control}
                      render={({ field }) => (
                        <Select {...field}>
                          <option value="created">Created</option>
                          <option value="in_progress">In Progress</option>
                          <option value="completed">Completed</option>
                        </Select>
                      )}
                    />
                    <FormErrorMessage>
                      {errors.orderStatus && errors.orderStatus.message}
                    </FormErrorMessage>
                  </FormControl>
                </Flex>
              </BoxWrapper>
              <Text mt={4}>Buyer Information</Text>
              <BoxWrapper>
                <OrderBuyerSection
                  errors={errors}
                  control={control}
                  initialValue={selectedOrderForEdit?.buyer}
                  onChange={(e) => setValue("buyerId", e.target.value)}
                />
              </BoxWrapper>
              <Text mt={4}>Seller Information</Text>
              <BoxWrapper>
                <OrderSellerSection
                  errors={errors}
                  control={control}
                  initialValue={selectedOrderForEdit?.seller}
                  onChange={(e) => setValue("sellerId", e.target.value)}
                />
              </BoxWrapper>
            </DrawerBody>
            <DrawerFooter>
              <Button
                type="submit"
                colorScheme="blue"
                variant="solid"
                width={"200px"}
                mb={4}
                position={"absolute"}
                bottom={5}
                right={5}
                isDisabled={!isDirty || !isValid}
                onClick={() => document.activeElement.blur()}
              >
                {selectedOrderForEdit ? "Update Order" : "Add Order"}
              </Button>
            </DrawerFooter>
          </form>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default OrderDrawer;
