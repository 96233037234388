import React from "react";
import {
  Box,
  ChakraProvider, 
  ColorModeProvider,
} from "@chakra-ui/react";
import { Route, Routes, Navigate } from "react-router-dom";
import Login from "./Pages/Login";
import { AuthProvider, useAuth } from "./auth/AuthProvider";
import TruckDashboard from "./Pages/trucks/TruckDashboard";
import PrivateRoute from "./atoms/PrivateRoute";
import NotFoundPage from "./atoms/NotFound";
import PublicRoute from "./atoms/PublicRoute";
import BuyerDashboard from "./Pages/buyers/BuyerDashboard";
import SellerDashboard from "./Pages/sellers/SellerDashboard";
import Feedback from "./Pages/feedback";
import RolesBasedTrips from "./roles-based-components/RolesBasedTrips";
import RoleBasedOrders from "./roles-based-components/RoleBasedOrders";
import RoleBasedDashboard from "./roles-based-components/RoleBasedDashboard";

const Layout = ({ children }) => {
  return <Box>{children}</Box>;
};

const App = () => {
  const { token, rohan, isAuthenticated } = useAuth();

  return (
    <ChakraProvider>
      <ColorModeProvider
        options={{
          initialColorMode: "light",
          useSystemColorMode: false,
        }}
      >
        <AuthProvider
          token={token}
          rohan={rohan}
          isAuthenticated={isAuthenticated}
        >
          <Layout>
            <Routes>
              <Route
                path="/"
                element={
                  <Navigate to={"/login"} />
                }
              />
              <Route element={<PublicRoute />}>
                <Route path="/login" element={<Login />} />
              </Route>
              <Route element={<PrivateRoute />}>
                <Route path="/dashboard" element={<RoleBasedDashboard />} />
                <Route path="/order" element={<RoleBasedOrders />} />
                <Route path="/truck" element={<TruckDashboard />} />
                <Route path="/trip" element={<RolesBasedTrips />} />
                <Route path="/buyer" element={<BuyerDashboard />} />
                <Route path="/sellers" element={<SellerDashboard />} />
                <Route path="/feedback" element={<Feedback />} />
              </Route>
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </Layout>
        </AuthProvider>
      </ColorModeProvider>
    </ChakraProvider>
  );
};

export default App;
