import React, { useState } from "react";
import { useGet } from "../../hooks/api-call";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Select,
  Text,
} from "@chakra-ui/react";
import NumberInput from "../../atoms/NumberInput";

const OrderFilters = ({ fetchOrdersData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [filters, setFilters] = useState({
    buyerId: "",
    materialId: "",
    tripStageId: "",
    minimumQuantity: "",
  });

  const { responseData: buyers } = useGet(`buyers`);
  const { responseData: minerals } = useGet(`minerals`);
  const { responseData: poNumbers } = useGet(`activeOrderIds`);

  
  const handleChange = ({ target: { name, value } }) => {
    setFilters((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleSearch = (data) => {
    fetchOrdersData("0", data || filters);
  };

  const handleClearFilters = () => {
    const data = {
      buyerId: "",
      materialId: "",
      tripStageId: "",
      minimumQuantity: "",
    };
    setFilters(data);
    setTimeout(() => handleSearch(data), 100);
  };

  const handleOpenFilter = () => {
    setIsOpen((prev) => {
      const element = document.getElementById("truck-filter-section");
      if (prev) {
        element.style.height = "0px";
        setFilters({
          ownerId: "",
          driverId: "",
          truckHypothecated: "",
        });
      } else {
        setTimeout(() => {
          element.style.height = element.scrollHeight + "px";
        });
      }
      return !prev;
    });
  };

  return (
    <Box
      mt={5}
      bg={"#fff"}
      p={4}
      boxSizing="border-box"
      borderRadius={"5px"}
      boxShadow={"base"}
    >
      <Flex
        alignItems={"center"}
        style={{ userSelect: "none" }}
        mb={isOpen ? 2 : 0}
        cursor={"pointer"}
        onClick={handleOpenFilter}
      >
        {isOpen ? <BsChevronUp /> : <BsChevronDown />}
        <Text ml={2} fontWeight={"bold"}>
          Filter
        </Text>
      </Flex>
      <Box
        id="truck-filter-section"
        h={0}
        transition={"height 0.4s ease-out"}
        overflow={"hidden"}
        bg={"#eef2f5"}
        p={isOpen && 2}
        borderRadius={5}
      >
        <Flex gap={2} align="end">
          {/* Form Field 3 */}
          <FormControl>
            <FormLabel color="gray.600">Po number</FormLabel>
            <Select
              name="poNumber"
              onChange={handleChange}
              value={filters.poNumber}
              placeholder={"Choose po number"}
            >
              {poNumbers &&
                poNumbers.length > 0 &&
                poNumbers.map((poNumber, index) => {
                  return (
                    <option key={poNumber.orderId} value={poNumber.poNumber}>
                      {poNumber?.poNumber}
                    </option>
                  );
                })}
            </Select>
          </FormControl>

          <FormControl>
            <FormLabel color="gray.600">Buyers</FormLabel>
            <Select
              name="buyerId"
              onChange={handleChange}
              value={filters.buyerId}
              placeholder={" Select Buyers"}
            >
              {buyers &&
                buyers.length > 0 &&
                buyers.map((buyer, index) => {
                  return (
                    <option value={buyer.buyerId} key={index}>
                      {buyer?.buyerName}
                    </option>
                  );
                })}
            </Select>
          </FormControl>

          {/* Form Field 2 */}
          <FormControl>
            <FormLabel color="gray.600">Minerals</FormLabel>
            <Select
              name="materialId"
              onChange={handleChange}
              value={filters.materialId}
              placeholder={"Choose minerals"}
            >
              {minerals &&
                minerals.length > 0 &&
                minerals.map((mineral, index) => {
                  return (
                    <option key={mineral.materialId} value={mineral.materialId}>
                      {mineral?.materialName}{" "}
                    </option>
                  );
                })}
            </Select>
          </FormControl>

          {/* Form Field 3 */}
          <FormControl>
            <FormLabel color="gray.600">Quantity</FormLabel>
            <NumberInput
              name="minimumQuantity"
              onChange={handleChange}
              placeholder="Enter quantity"
              value={filters.minimumQuantity}
            />
          </FormControl>
        </Flex>
        <Flex justifyContent={"flex-end"} mt={3}>
          <Button
            variant={"solid"}
            bg={
              Object.values(filters).filter(Boolean).length === 0
                ? " "
                : "gray.200"
            }
            mr={3}
            w="100px"
            onClick={handleClearFilters}
            color={
              Object.values(filters).filter(Boolean).length === 0
                ? "gray.300"
                : ""
            }
          >
            Clear all
          </Button>
          <Button
            variant={"solid"}
            bg="blue.600"
            color="white"
            w="180px"
            onClick={() => handleSearch()}
            _hover={{
              backgroundColor: "#054180",
            }}
          >
            Search
          </Button>
        </Flex>
      </Box>
    </Box>
  );
};

export default OrderFilters;
